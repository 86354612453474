import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import cryptoPayments from "@/assets/cryptoPayments.svg";
import allPayments from "@/assets/allPayments.svg";
import { usePayment } from "../api/fetchPaymentCurrency";
import QRCodeGenerator from "./QRCodeGenerator";

export const BankAccounts = () => {
  const { data } = usePayment({});
  const [isLoading, setIsLoading] = useState(false);
  console.log(data);
  const [qrData, setQrData] = useState("https://www.google.com/");
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };

  return (
    <Grid item xs={6} md={6}>
      {" "}
      <Card
        style={cardStyle}
        sx={{ p: theme.spacing(0.2), m: theme.spacing(0.2) }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Item sx={{ display: "contents" }}>
              {" "}
              <Typography variant="h5">Your wallet receive address</Typography>
              <Typography
                variant="h6"
                sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                style={{ fontSize: "12px" }}
              >
                Note: You can only send USDT/USDC on ERC20 network!
              </Typography>
            </Item>
            <Divider sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }} />
            {/* <Item>
                    {" "}
                    <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
                      <FormControl fullWidth={true}>
                        <InputLabel
                          htmlFor="email"
                          shrink={true}
                          sx={{ color: "#FFFFFF" }}
                        >
                          Crypto Amount
                        </InputLabel>
                        <TextField
                          margin="normal"
                          required
                          id="amount"
                          name="amount"
                          autoComplete="amount"
                          autoFocus
                          placeholder="Enter Amount"
                          sx={{fontSize:{xs:"12px", md:"14px"}}}
                        />
                      </FormControl>
                    </Grid>
                  </Item> */}
            <Item>
              {" "}
              <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="email"
                    shrink={true}
                    sx={{ color: "#FFFFFF", mb: 1, position: "relative" }}
                  >
                    Copy your wallet address:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    sx={{ bottom: "13px !important" }}
                    id="demo-simple-select"
                    label="Crypto Currency"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: "black",
                          "& .MuiMenuItem-root": {
                            padding: 1,
                            fontFamily: "Ubuntu",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                          },
                        },
                      },
                    }}
                  >
                    {/* {data?.length?data?.map((page) => (
                                          <MenuItem value={page?.currency}>
                                            <Typography textAlign="center">{page?.currency}</Typography>
                                          </MenuItem>
                                        )):
                                        
                                        <MenuItem value='N/A'>
                                        <Typography textAlign="center">N/A</Typography>
                                      </MenuItem>} */}
                  </Select>
                </FormControl>
              </Grid>
            </Item>
            <h4 style={{ color: "white" }}>Scan the QR CODE:</h4>
            <div style={{ textAlign: "center" }}>
              <QRCodeGenerator data={qrData} />
            </div>

            <Item>
              <Grid xs={12} md={12}>
                {" "}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Confirm Now
                </Button>
              </Grid>
            </Item>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};
