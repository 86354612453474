import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { Card, CardContent, Grid, List, ListItem, Paper, Stack } from '@mui/material';
import { css } from '@emotion/react'
import footerLogo from "@/assets/footerLogo.png";
import logoCrypto from '@/assets/logoCrypto.svg';
import { theme } from './theme';


export default function Footer() {

    return (
      <Paper 
      sx={{
      // marginTop: 'calc(10% + 10px)',
      bottom: 0,
      backgroundColor:" #050518",
      padding:{xs:theme.spacing(0.1)},
      paddingLeft:{md:theme.spacing(1)},
      }} component="footer" square variant="outlined">
        <Grid container>
          <Grid item md={2} lg={2} xl={2}>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-start",
              display: "flex",
              my:1
            }}
          >
             <Stack spacing={2} flex="1 0 1">
                <List sx={{pt:0}}>
            <ListItem sx={{pt:0}}>
              <Link underline="none"  href="#home" >
              <img src={logoCrypto} style={{height:"76px"}}/>
              
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="#company">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}}>
              ADDRESS</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="#portfolio">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}}>
              Klimentská 46, 110 02 Petrská čtvrť,<br/>  Prague
Czech Republic 
             </Typography>
              </Link>
            </ListItem>
          </List>
                </Stack>

              
          </Box>
  </Grid>
  <Grid item md={6} lg={6} xl={5}>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "space-between",
              display: "flex",
              mb: 2,
            }}
          >
            <List>
            <ListItem>
              <Link underline="none"  href="#home" >
              <Typography component="h3" variant="h3" >POLICIES</Typography>
              
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="/termsofUse">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}}>
              Terms of Use</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="/privacyNotice">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}}>
              Privacy Notice</Typography>
              </Link>
            </ListItem>
          </List>

          <List>
            <ListItem>
              <Link underline="none"  href="#home" >
              <Typography component="h3" variant="h3" >ABOUT US</Typography>
              
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="/app/aboutus">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}}>
              About Us</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="/app/contactUs">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}}>
              Contact Us</Typography>
              </Link>
            </ListItem>
          </List>

          <List>
            <ListItem>
              <Link underline="none"  href="#home" >
              <Typography component="h3" variant="h3" >QUICK LINKS</Typography> 
              
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="/rules">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              Rules</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="/Licensing">
             
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              Licensing</Typography>
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="/app/faq" >
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              FAQs</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="/donations" >
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              Donations</Typography>
             
              </Link>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <Link underline="none"  href="#home" >
              <Typography component="h3" variant="h3" >PAYMENTS</Typography> 
              
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="#company">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              DAI</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="#portfolio">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              USDC</Typography>
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="#blog" >
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              Tether - USDT</Typography>
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="#blog" >
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              Binance Coin - BUSD</Typography>
              </Link>
            </ListItem>
            <ListItem >
              <Link underline="none"  href="#blog" >
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} >
              Fiat Currency</Typography>
              </Link>
            </ListItem>
          </List>
          
       
          </Box>
          </Grid>
          <Grid item md={4} lg={4} xl={5} sx={{pt:theme.spacing(0.2),display:"flex",justifyContent:"center"}}>
          <img src={footerLogo} alt="footerLogo" style={{textAlign:"center"}} width="188px" height="126px"/>
          </Grid>
          </Grid>
      </Paper>
    );
  }