import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Menu,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import { MRT_ColumnDef, MantineReactTable } from 'mantine-react-table';
import { MantineProvider } from '@mantine/core';
import { Title } from "@material-ui/icons";
import { DatePickerInput } from '@mantine/dates';
import CurrencyFormat from "react-currency-format";
import { useAuth } from "@/lib/auth";
import { getTicketList, useTicketList } from "../api/getTicketList";
import { useInvestedCoins } from "../../History/api/getInvestedCoins";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import "./table.css";
// import "./table.css";
import { Payment } from "../types";
import yellowt from "@/assets/yellow-t.png";
import MervIcon from "@/assets/mervIcon.svg";
import { useTicketStatus } from "../api/getTicketStatus";


//nested data is ok, see accessorKeys in ColumnDef below

type letterValues = {
  email: string;
};

const initialValues: letterValues = {
  email: '',
};

export const Ticket = () => {
  const { user } = useAuth();
  const FriendsQuery = useTicketList({});
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const ticketStatus = useTicketStatus({}) || {
    data: [],
  };
  //console.log(FriendsQuery.data)
  console.log(FriendsQuery)
  const [formValues, setFormValues] = useState<letterValues>(initialValues);
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };



  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  const columns = useMemo<MRT_ColumnDef<Payment>[]>(
    () => [
      {
        accessorKey: 'userName',
        header: 'User Name',
        size: 100,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
        id: 'Date',
        header: 'Date',
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        //Custom Date Picker Filter from @mantine/dates
        Filter: ({ column }) => (
          <DatePickerInput
            // placeholder="Filter by Start Date"
            clearable
            onChange={(newValue: Date) => {
              column.setFilterValue(newValue);
            }}
            value={column.getFilterValue() as Date}
            modalProps={{ withinPortal: true }}
          />
        ),
      },

      {
        accessorKey: 'ticketNft',
        header: 'NFT Id',
        size: 100,
        //custom conditional format and styAling
      },
      {
        accessorKey: 'ticketAmt',
        header: 'Amount',
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),

        //custom conditional format and styling
      },
      {
        accessorKey: 'nftStatus', //hey a simple column for once
        header: 'Status',
        size: 100,
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              color:
                cell.getValue<string>() === 'Success'
                  ? '#95D23D'
                  : cell.getValue<string>() === 'Pending'
                    ? '#FF9900'
                    : '#FF4545',
              borderRadius: "4px",
              border: cell.getValue<string>() === 'Success'
                ? '1px solid rgba(149, 210, 61, 0.41)'
                : cell.getValue<string>() === 'Pending'
                  ? '1px solid rgba(231, 214, 61, 0.5)'
                  : '1px solid rgba(255, 69, 69, 0.4)',
              backgroundColor: cell.getValue<string>() === 'Approved'
                ? 'rgba(149, 210, 61, 0.19)'
                : cell.getValue<string>() === 'Pending'
                  ? 'rgba(231, 214, 61, 0.1)'
                  : 'rgba(255, 69, 69, 0.05)',
              maxWidth: '9ch',
              padding: '4px',
            })}
          >
            {cell.getValue<string>()}
          </Box>
        ),
      }
    ],
    [],
  );

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container sx={{ mt: { md: "theme.spacing(0.5)", xs: "10px" } }}>
        <Grid item xs={12} md={12}>
          {" "}
          <Card
            style={cardStyle}
            sx={{ p: { md: "theme.spacing(0.3)", xs: "0px" }, m: theme.spacing(0.2) }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Item>
                  {" "}
                  <Typography variant="h5">My Tickets</Typography>
                  <Typography variant="h5" sx={{ position: "absolute", right: "10%", margin: "-18px", pt: { xs: "5px", md: "0" } }}>
                    <img src={MervIcon} className="log-merv-wallet" style={{ height: "22px", width: "22px", position: "relative", top: "0px" }} />   {coins?.data?.data}   <br />
                    <img src={yellowt} className="log-merv-wallet" style={{ height: "22px", width: "22px", position: "relative", top: "-1px", right: "5px" }} /><CurrencyFormat
                      value={dataWallet?.data?.data?.walletAmount}
                      thousandSeparator={"."}
                      displayType={"text"}
                      decimalSeparator={","}
                    /></Typography>
                </Item>

                <Item>
                  <Grid item sx={{ width: '100%', overflowX: 'auto' }}>
                    <MantineProvider
                      withGlobalStyles
                      withNormalizeCSS
                      theme={{
                        breakpoints: {
                          xs: '30em',
                          sm: '48em',
                          md: '64em',
                          lg: '74em',
                          xl: '90em',
                        },
                        fontSizes: { xs: "12px", md: "14px" },
                        colorScheme: 'dark',
                      }}
                    >
                      <MantineReactTable
                        columns={columns}
                        data={FriendsQuery.data || []}
                        positionPagination="top"
                        initialState={{ showColumnFilters: true }}
                        positionToolbarAlertBanner="bottom"
                      />
                    </MantineProvider>
                  </Grid>
                </Item>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </Box>
  );
};
