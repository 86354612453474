import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import checkForPrizes from "@/assets/checkForPrizes.svg";
import buyTickets from "@/assets/buyTickets.svg";
import waitForTheDraw from "@/assets/waitForTheDraw.png";
import dollar from "@/assets/dollar.svg";
import Countdown from "react-countdown";

const WinningDetails = ({data}) => {
  var classes = {
    content: {
      background: "#050518",
      borderRadius: "25.2165px",
    },
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const [countDown, setCountDown] = useState(data?.upCommingLotteries[0]?.lotteryDrawDate);
  console.log("kkkk Data",data?.firstupComRecords[0]?.lotteryDrawDate)
  return (
    <Grid
      container
      display={"flex"}
      direction={"row"}
      justifyContent={"flex-around"}
      sx={{p:{xs:"20px"}}}
      //alignItems={"flex-start"
    >
      <Grid item xs={12} sm={12} md={12} >
        <Typography component="h4" variant="h4"  sx={{ 
    marginBottom: {xs:"10px",  md:"10px"},
    marginTop: {xs:"0px",  md:"0px"}}}>
          START EARNING TODAY!
        </Typography>
        <Grid
          container
          display={"flex"}
          direction={"row"}
          spacing={8}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{pl:{xs:'40px !important'}}}
        >
          <Grid item xs={4} sm={4} md={4} lg={4} sx={{pl:{xs:"20px !important"}}}>
            <a href="/auth/login" style={{    textDecoration: "none"}}>
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={classes.content}
            >
              <CardContent>
                <Stack
                  spacing={2}
                  flex="1 0 1"
                  justifyContent={center}
                  alignItems={center}
                >
                  <Box>
                    {" "}
                    <img
                      src={buyTickets}
                      alt="buyTickets"
                      style={{ textAlign: "center" }}
                      width="89px"
                      height="89px"
                    />
                  </Box>

                  <Box>
                    <Typography component="h4" variant="h4">
                      BUY TICKETS
                    </Typography>
                  </Box>
                  
                  <Box>
                    <Typography variant="subtitle2" sx={{ opacity: "0.7", textAlign:"center" }}>
                    Buy your tickets <br/>from 1 dollar /USDT
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            </a>   
          </Grid>
          <Grid item xs={4} sm={4} md={4} sx={{pl:{xs:"20px !important"}}}>
          <a href="/auth/login" style={{    textDecoration: "none"}}>
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={classes.content}
            >
              <CardContent>
                <Stack
                  spacing={2}
                  flex="1 0 1"
                  justifyContent={center}
                  alignItems={center}
                >
                  <Box>
                    {" "}
                    <img
                      src={waitForTheDraw}
                      alt="buyTickets"
                      style={{ textAlign: "center" }}
                      width="89px"
                      height="89px"
                    />
                  </Box>

                  <Box>
                    <Typography component="h4" variant="h4">
                      WAIT FOR THE DRAW
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ opacity: "0.7" , textAlign:"center"}}>
                    Wait for the next live draw at <br />
                    <span><Countdown date={new Date(data?.upCommingLotteries[0]?.lotteryDrawDate)} /></span>
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            </a>
          </Grid>
          <Grid item xs={4} sm={4} md={4} sx={{pl:{xs:"20px !important"}}}>
          <a href="/auth/login" style={{    textDecoration: "none"}}>
            <Card
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              style={classes.content}
            >
              <CardContent>
                <Stack
                  spacing={2}
                  flex="1 0 1"
                  justifyContent={center}
                  alignItems={center}
                >
                  <Box>
                    {" "}
                    <img
                      src={checkForPrizes}
                      alt="buyTickets"
                      style={{ textAlign: "center" }}
                      width="89px"
                      height="89px"
                    />
                  </Box>

                  <Box>
                    <Typography component="h4" variant="h4">
                      CHECK FOR PRIZES
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ opacity: "0.7", textAlign:"center" }}>
                    Discover if you won a prize <br/>in the last lotteries.
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}  sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" ,mt:{xs:"20px", md:"50px"}}} >
        <Grid container   sx={{p:{xs:"20px"}}}>
        <Grid item xs={6} sm={6} md={6}  sx={{  
    marginBottom: "25px",
    marginTop: "40px"}} >
        <Typography component="h4" variant="h4">
          Winning Prize Details:
        </Typography>
        <Typography
          component="h6"
          variant="h6"
          sx={{ opacity: "0.7", mt: "25px" }}
        >
          <ul>
            <li>Only sold tickets participate in the lottery. </li>
            <li>Each lottery has multiple spins. </li>
            <li>
              Total Prize money is the same as the total of ticket sales.{" "}
            </li>
            <li>Payout to the winners is 80% of the ticket sale. </li>
            <li>All prices will be awarded to the players in the lottery.</li>
            <li>
              Prize-money is in percentage of the amount of tickets sold. (50%
              tickets sold = 50% of prizes)
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography component="h4" variant="h4" sx={{ mt: "20px" }}>
          Lottery Instructions
        </Typography>
        <Typography
          component="h6"
          variant="h6"
          sx={{ opacity: "0.7", mt: "25px" }}
        >
          <ul>
            <li>Choose the lottery you want to play in. </li>
            <li>Select the number of tickets you want to buy. </li>
            <li>
              Set the speed and deceleration of the wheel ( not required ). The
              average of the outcome <br />
              decides the speed and deceleration of the wheel in the spin.{" "}
            </li>
            <li>Click the buy button and pay for the tickets. </li>
            <li>One hour before the draw the ticket sale will be closed. </li>
            <li>A random program will now assign your ticket number.</li>
            <li>The spin will always start with the number 000000.</li>
            <li>
              The 2e spin will start at the winning number of the 1e spin.
            </li>
            <li>
              The 3e spin will start at the winning number of the 2e spin.
            </li>
            <li>The 4e spin…etc. </li>
          </ul>
        </Typography>
      </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
};

export default WinningDetails;
