import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Payment } from '../types';

export const getTicketList = async (): Promise<Payment[]> => {
  const response = await axios.get(`/api/v1/user/ticketNFTList`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getTicketList;

type UseFriendsListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTicketList = ({  config }: UseFriendsListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getTicketList(),
    ...config,
  });
};