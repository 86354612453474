import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  ListSubheader,
  ListSubheaderProps,
  SelectChangeEvent,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import cryptoPayments from "@/assets/cryptoPayments.svg";
import allPayments from "@/assets/allPayments.svg";
import { usePayment } from "../api/fetchPaymentCurrency";
import axios from "axios";
import storage from "@/utils/storage";
import Swal from "sweetalert2";
import VerifyWithdrawOTP1 from "@/components/Modals/verifyWithdrawOTP";

export const WithDraw = () => {
  // const {data} = usePayment({});
  const [totalWalletBal, setTotalWalletBal] = useState(0);
  const [winArray, setWinArray] = useState([
    {
      LotteryName: "",
      amount: "",
      lotteryStatus: "",
      transactionId: "",
      winAmount: "",
    },
  ]);
  const [arr, setarr] = useState([]);
  const [loading, setLoading] = useState(false)
  const [walletAddrArray, setWalletAddrArray] = useState([
    "BTC",
    "LTC",
    "XPM",
    "MAZA",
    "NMC",
    "ETH",
    "STRK",
    "XDC",
    "PPC",
    "ETC",
    "BCH",
    "DPOS",
    "TRON",
    "XTZ",
    "BSV",
    "ALGO",
    "SOL",
    "DOT",
    "AVAX",
    "MOB",
    "ICP",
    "DESO",
    "LUNC",
    "LUNA",
    "XLM",
    "EOS",
    "LBC",
    "XRP",
    "STX",
    "VTC",
    "HBAR",
    "ZEC",
    "XMR",
    "BTG",
    "DOGE",
    "MATIC",
    "BNB",
    "ETHER",
    "APT",
    "NEAR",
    "MIOTA",
    "XNO"
  ]);
  const [usdcWalletArray, setUSDCWalletArray] = useState([
    "Algorand",
    "Arbitrum",
    "Avalanche",
    "Base",
    "Celo",
    "Ethereum",
    "Flow",
    "Hedera",
    "Noble",
    "OP Mainnet",
    "Polkadot",
    "Polygon PoS",
    "Solana",
    "Stellar"
  ]);
  const [usdtWalletArray, setUSDTWalletArray] = useState([
    "Ethereum", "Omni", "Tron", "EOS", "Liquid", "Algorand"
  ])
  const [busdWalletArray, setBUSDWalletArray] = useState([
    "Avalance", "BNB Chain", "Ethereum", "Polygon Networks"
  ])
  const [currencyArray, setCurrencyArray] = useState([
    {
      currency: "select",
      paymentMethodId: "",
    },
  ]);

  const [formValues, setFormValues] = useState({
    amount: "",
    cryptoType: "select",
    paymentMethodId: "",
    walletAddress: "",
    walletNetwork: "select",
  });
  const [userData, setUserData] = useState({
    userId: "",
    emailId: ""
  })
  useEffect(() => {
    getUserData()
  }, [])
  const getUserData = async () => {
    let token = storage.getToken();
    const response = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/userdetail`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setUserData({ userId: response?.data?.data?._id, emailId: response?.data?.data?.email })
  }
  const [currency, setCurrency] = useState("");
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let token = storage.getToken();
    const response = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/fetchUserWalletBalance`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const data = response.data.data.walletAmount;
    setTotalWalletBal(data);
    setWinArray(data.myLotteryArrray);
    const currencyResponse = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/fetchPaymentCurrency`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const currencyData = currencyResponse.data.data;
    setCurrencyArray(currencyData);
  };
  const [isOpen, setIsopen] = useState(false);
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange2 = (e) => {
    let reqData = currencyArray.filter((i) => i.currency === e.target.value);
    const cryptoType = reqData[0].currency;
    const paymentMethodId = reqData[0].paymentMethodId;
    setCurrency(cryptoType);
    setFormValues({
      amount: formValues.amount,
      walletAddress: formValues.walletAddress,
      walletNetwork: "",
      cryptoType: cryptoType,
      paymentMethodId: paymentMethodId,
    });
  };

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)
      let token = storage.getToken();
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/extWalletWithdrawReqOTP`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setIsopen(true)
      // Swal.fire({
      //   background: "black",
      //   color: "#ffff",
      //   icon: "success",
      //   title: "Success!",
      //   text: res?.data.message,
      // }).then(() => {
      //   setLoading(false)
      //   window.location.reload();
      // });
    } catch (error: any) {
      setLoading(false)
      Swal.fire({
        background: "black",
        color: "#ffff",
        text: error.response.data.message,
      }).then(() => {
        window.location.reload();
      });
    }
  };
  const handleClose = () => {
    setIsopen(false);
  };
  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        {/* <form> */}
        <Grid container sx={{ mt: theme.spacing(0.5) }}>
          <Grid item xs={12} md={6}>
            {" "}
            <Card
              style={cardStyle}
              sx={{ p: theme.spacing(0.3), m: theme.spacing(0.2) }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Item>
                    {" "}
                    <Typography variant="h5">Withdraw</Typography>
                  </Item>
                  <Item>
                    <Typography
                      variant="h6"
                      sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                    >
                      Select Payment System
                    </Typography>
                  </Item>
                  <Divider
                    sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }}
                  />
                  <Item className="Select-Payment-System">
                    <Grid item xs={12} sm={6} md={12}>
                      <Card
                        style={content}
                        sx={{
                          background: "rgba(255, 255, 255, 0.03) !important",
                          display: "flex",
                        }}
                        className={"activePayment"}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            borderRadius: "10px",
                            p: theme.spacing(0.3),
                            width: "150.06px",
                            height: "129.68px",
                          }}
                          image={cryptoPayments}
                          alt="Live from space album cover"
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto", p: "0", mt: 2 }}>
                            <Stack spacing={2} flex="1 0 1">
                              <Box>
                                <Typography component="h6" variant="h6">
                                  Crypto Payment
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  component="h3"
                                  variant="h3"
                                  sx={{ color: "#A3A9AD" }}
                                >
                                  Minimum - 5(USD) | Maximum - 1,000,000(USD)
                                </Typography>
                              </Box>

                              <Box>
                                <Button
                                  sx={{
                                    px: "19px",
                                    py: "7.73px",
                                    marginBottom: "19px !important",
                                    backgroundColor: "rgba(60, 55, 255, 0.15)",
                                    color: "#3C37FF",
                                    borderRadius: "19px",
                                  }}
                                >
                                  Payments.Commision - 0
                                </Button>
                              </Box>
                            </Stack>
                          </CardContent>
                        </Box>
                      </Card>
                    </Grid>
                  </Item>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            {" "}
            <Card
              style={cardStyle}
              sx={{ p: theme.spacing(0.2), m: theme.spacing(0.2) }}
            >
              <CardContent>
                <Stack spacing={1}>
                  <Item sx={{ display: "contents" }}>
                    {" "}
                    <Typography variant="h5">
                      Select Withdrawal Amount
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                    >
                      Enter details to proceed
                    </Typography>
                  </Item>
                  <Divider
                    sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }}
                  />
                  <Item>
                    <Grid item xs={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          p: 1,
                          m: 1,
                          bgcolor: "transparent",
                          border: "0.5px solid rgba(255, 255, 255, 0.2)",
                          borderRadius: "10px",
                          opacity: 0.6,
                        }}
                      >
                        <Box sx={{ p: "15px", flex: "30%" }}>
                          <Typography component="h6" variant="h6">
                            Total Available
                          </Typography>
                        </Box>

                        <Box
                          sx={{ p: "15px", flex: "30%", textAlign: "right" }}
                        >
                          <Typography component="h6" variant="h6">
                            $ {totalWalletBal}
                          </Typography>
                        </Box>
                        <Box sx={{ p: "15px", flex: "100%" }}>
                          <Divider
                            orientation="horizontal"
                            flexItem
                            sx={{
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                            }}
                          />
                        </Box>
                        {/* <Box sx={{ p: "15px", flex: "35%" }}>
                          <Typography component="h6" variant="h6">
                            Balance Pending to Withdraw
                          </Typography>
                        </Box>
                        <Box
                          sx={{ p: "15px", flex: "30%", textAlign: "right" }}
                        >
                          <Typography component="h6" variant="h6">
                            $ 5000
                          </Typography>
                        </Box> */}
                      </Box>
                    </Grid>
                  </Item>
                  <Typography variant="h6" sx={{ display: "block", mt: 2, color: "blue" }}>
                    Select Win Lottery Price
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                      style={{ width: "100%", marginLeft: "5px" }}
                      id="outlined-multiline-flexible"
                      placeholder="Enter Withdrawal Amount"
                      multiline
                      maxRows={4}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <PermIdentityIcon style={{ color: "white" }} />
                      //     </InputAdornment>
                      //   ),
                      // }}
                      value={formValues?.amount}
                      name="amount"
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Typography variant="h6" sx={{ display: "block", mt: 2, color: "blue" }}>
                    External Wallet Address
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                      style={{ width: "100%", marginLeft: "5px" }}
                      id="outlined-multiline-flexible"
                      placeholder="Enter External Wallet Address"
                      multiline
                      maxRows={4}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <PermIdentityIcon style={{ color: "white" }} />
                      //     </InputAdornment>
                      //   ),
                      // }}
                      value={formValues?.walletAddress}
                      name="walletAddress"
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Typography variant="h6" sx={{ display: "block", mt: 2, color: "blue" }}>
                    Select Crypto-Currency
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      onChange={handleChange2}
                      value={formValues.cryptoType}
                      defaultValue="select"
                      name="cryptoType"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: "black",
                            "& .MuiMenuItem-root": {
                              padding: 1,
                              fontFamily: "Ubuntu",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value="select">
                        <em>Select</em>
                      </MenuItem>
                      {currencyArray?.map((page) => (
                        <MenuItem value={page?.currency}>
                          <Typography
                            textAlign="left"
                            style={{ fontSize: "20px" }}
                          >
                            {page.currency}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="h6" sx={{ display: "block", mt: 2, color: "blue" }}>
                    Wallet Address Network
                  </Typography>
                  {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <TextField
                      style={{ width: "100%", marginLeft: "5px" }}
                      id="outlined-multiline-flexible"
                      placeholder="Wallet Address Network"
                      multiline
                      maxRows={4}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <PermIdentityIcon style={{ color: "white" }} />
                      //     </InputAdornment>
                      //   ),
                      // }}
                      value={formValues?.walletNetwork}
                      name="walletNetwork"
                      onChange={handleChange}
                    />
                  </FormControl> */}
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      value={formValues?.walletNetwork}
                      name="walletNetwork"
                      onChange={handleChange}
                      defaultValue="select"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: "black",
                            "& .MuiMenuItem-root": {
                              padding: 1,
                              fontFamily: "Ubuntu",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "14px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem disabled value="select">
                        <em>Select</em>
                      </MenuItem>
                      {/* {walletAddrArray?.map((page) => (
                        <MenuItem value={page}>
                          <Typography
                            textAlign="left"
                            style={{ fontSize: "20px" }}
                          >
                            {page}
                          </Typography>
                        </MenuItem>
                      ))} */}
                      {currency === "USDC" && usdcWalletArray && (
                        usdcWalletArray.map((page) => (
                          <MenuItem value={page}>
                            <Typography textAlign="left" style={{ fontSize: "20px" }}>
                              {(page).toUpperCase()}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                      {currency === "USDT" && usdtWalletArray && (
                        usdtWalletArray.map((page) => (
                          <MenuItem value={page}>
                            <Typography textAlign="left" style={{ fontSize: "20px" }}>
                              {(page).toUpperCase()}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                      {currency === "BUSD" && busdWalletArray && (
                        busdWalletArray.map((page) => (
                          <MenuItem value={page}>
                            <Typography textAlign="left" style={{ fontSize: "20px" }}>
                              {(page).toUpperCase()}
                            </Typography>
                          </MenuItem>
                        ))
                      )}
                      {currency !== "USDC" && currency !== "USDT" && currency !== "BUSD" && (
                        <MenuItem value="">
                          <em>No options available</em>
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <Item>
                    <Grid xs={12} md={12}>
                      {" "}
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                      // onClick={(e)=>{ setIsopen(true)}}      
                      >
                        {loading ? <>Withdraw Request</> : <>Withdraw Request</>}
                      </Button>
                    </Grid>
                  </Item>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <VerifyWithdrawOTP1
          open={isOpen}
          onClose={handleClose}
          formValues={formValues}
          userData={userData}
        // onSuccess={handleLogin}
        />
      </form>

    </Box>
  );
};
