import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Input,
  LinearProgress,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import promotionsBackground from "@/assets/promotionsBackground.png";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import promotionSecond from "@/assets/promotionSecond.png";
import promotionimg from "@/assets/promotionimg.png";
import { theme } from "@/components/Elements/theme";
import { url } from "inspector";
import HomeScreenImg from "@/assets/earth.jpg";
import { BuyCoin } from "./buyCoin";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Countdown from "react-countdown";
import storage from "@/utils/storage";
import { useBuyCoins } from "../api/buyCoins";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

// import { UsePromotionByReferList } from "../api/getPromotionByRefer";
// import { UsePromotionByBuyList } from "../api/getPromotionByBuy";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
    [theme.breakpoints.down("md")]: {
      height: "160px",
      marginTop: "10px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridThird: {
    background: `url(${promotionSecond})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "center",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
}));

export default function ICO() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [startTimeIco, setstartTimeIco] = useState(0);
  const [endTimeIco, setendTimeIco] = useState("");
  const [formValues, setFormValues] = useState({ no_of_token: 0 });
  const [amount, setTotalAmount] = useState(0);
  const [difference, setDifference] = useState(0);
  const [file, setFile] = useState("");
  const [tokenPrice, setTokenPrice] = useState(0);
  const handleSubstractCount = () => {
    if (count >= 1) {
      let countValue = count;
      setCount(count - 1);
      setFormValues({
        ...formValues,
        no_of_token: countValue - 1,
      });
      let ticketPrice: any = 10; // TO BE UPDATED FROM API
      let amount1 = formValues.no_of_token - 1;
      setTotalAmount(amount1);
    } else return;
  };
  const calculateTimeLeft = () => {
    const diff = +new Date(endTimeIco) - +new Date();
    setDifference(diff);
  };
  useEffect(() => {
    console.log("isLoading", loading);
    if (loading) {
      showLoadingPopup();
    }
  }, [loading]);
  useEffect(() => {
    getFile();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTimeLeft();
    }, 1000);
    return () => clearTimeout(timer);
  }, [difference]);
  const getFile = async () => {
    const response = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/icoList`
    );
    setstartTimeIco(response?.data.data[0].startInvTimeStamp);
    setendTimeIco(response?.data.data[0].endInvTimeStamp);
    setTokenPrice(response?.data.data[0].tokenPrice);
    setFile(
      `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${response?.data.data[0].icoDoc}`
    );
  };

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    background: "rgba(5, 5, 24, 0.8)",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
    margin: "10px 30px",
  };
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));
  const handleTenSubstractCount = () => {
    if (count >= 10) {
      let countValue = count;
      setCount(count - 10);
      setFormValues({
        ...formValues,
        no_of_token: countValue - 10,
      });
      let ticketPrice: any = 10; // TO BE UPDATED FROM API
      let amount1 = formValues.no_of_token - 10;
      setTotalAmount(amount1);
    } else return;
  };
  const navigate = useNavigate();
  //   const dataValueByRefer = UsePromotionByReferList({});
  //   const dataValueByBuy = UsePromotionByBuyList({});
  const auth = useAuth();
  const createPaymentMutation = useBuyCoins({ amount });

  console.log("count", count);
  var sweet_loader =
    '<div class="circular-progress"><div class="circle"></div><div class="mask full"><div class="fill"></div></div><div class="mask half"><div class="fill"></div><div class="fill fix"></div></div></div>';

  const handleCount = () => {
    let countValue = count;
    setCount(count + 1);
    setFormValues({
      ...formValues,
      no_of_token: countValue + 1,
    });
    let ticketPrice: any = 10; // TO BE UPDATED FROM API
    let amount1 = formValues.no_of_token + 1;
    setTotalAmount(amount1);
  };
  const createCryptoPayment = async (event) => {
    event.preventDefault();
    let token = storage.getToken();
    try {
      setLoading(true);
      console.log("here");
      if (token != null) {
        await createPaymentMutation
          .mutateAsync({
            data: {
              amount,
            },
          })
          .then((res) => {
            Swal.fire({
              background: "black",
              color: "#ffff",
              icon: "success",
              title: "Success!",
              text: "Buy Merv coin successfully",
            });
          });
      } else {
        navigate("/auth/login");
      }
      console.log("here2");
      setLoading(false);
      // window.location.reload();
    } catch (error: any) {
      console.log("here3");
      setLoading(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: `${error?.response?.data?.message}`,
      });
    }
  };
  const handleTenCount = () => {
    let countValue = count;
    setCount(count + 10);
    setFormValues({
      ...formValues,
      no_of_token: countValue + 10,
    });
    let ticketPrice: any = 10; // TO BE UPDATED FROM API
    let amount1 = formValues.no_of_token + 10;
    setTotalAmount(amount1);
  };
  const showLoadingPopup = () => {
    Swal.fire({
      text: "We are processing your purchase, one moment please",
      allowOutsideClick: false,
      showCancelButton: false,
      html: sweet_loader,
      customClass: "buy-now-pop-up",
      background: "black",
      color: "#ffff",
      showLoaderOnConfirm: true,
      timer: 1000000,
      showConfirmButton: false,
      timerProgressBar: true,
    }).then(
      function () {},
      // handling the promise rejection
      function (dismiss) {
        if (dismiss === "timer" && !loading) {
        }
      }
    );
  };
  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Open link in new tab
    link.setAttribute("rel", "noopener noreferrer"); // Security best practice
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {auth.user ? <Header /> : <HeaderValue />}
      <Box
        sx={{
          background: `url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          paddingTop: "110px",
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid
            item
            xs={12}
            md={12}
            className={classes.innerGrid}
            sx={{ p: theme.spacing(1), background: "none !important" }}
          >
            <Grid item xs={12} justifyContent={"space-around"} p={2}>
              <Typography
                variant="h2"
                align="center"
                // sx={{ mt: { md: "50px" } }}
              >
                ICO
              </Typography>
            </Grid>
            <Grid container>
              {/* <BuyCoin /> */}
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    backgroundColor: "rgba(5, 5, 24, 0.8)",
                    borderRadius: "10px",
                  }}
                />
                <Box sx={{ p: { xs: "34px", md: "30px" } }}>
                  <Grid
                    container
                    display={"flex"}
                    direction={"column"}
                    // marginTop={"60px"}
                    sx={{
                      backgroundColor: "rgba(5, 5, 24, 0.8)",
                      borderRadius: "10px",
                    }}
                  >
                    <Grid
                      container
                      display={"flex"}
                      direction={"row"}
                      spacing={2}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ marginBottom: "40px", p: theme.spacing(1) }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ color: "white", paddingInline: "0px !important" }}
                      >
                        <Box className="buy-box">
                          <h1 style={{ textAlign: "center" }}>
                            PRE-SALE ROUND 1
                          </h1>
                          <Box className="w-100 d-flex flex-column align-items-center justify-content-start text-center walletBox_top p-3">
                            <div className="timer">
                              <div className="time-card text-center">
                                <div className="value">
                                  {Math.floor(
                                    difference / (1000 * 60 * 60 * 24)
                                  )}
                                </div>
                                <div className="indicator">DAY</div>
                              </div>
                              :
                              <div className="time-card text-center">
                                <div className="value">
                                  {Math.floor(
                                    (difference / (1000 * 60 * 60)) % 24
                                  )}
                                </div>
                                <div className="indicator">HRS</div>
                              </div>
                              :
                              <div className="time-card text-center">
                                <div className="value">
                                  {Math.floor((difference / 1000 / 60) % 60)}
                                </div>
                                <div className="indicator">MINS</div>
                              </div>
                              :
                              <div className="time-card text-center">
                                <div className="value">
                                  {Math.floor((difference / 1000) % 60)}
                                </div>
                                <div className="indicator">SEC</div>
                              </div>
                            </div>
                            <Typography variant="h1">
                              BUY NOW BEFORE PRICE INCREASES!
                            </Typography>
                            <div className="progress">
                              <div
                                className="bar"
                                style={{ width: "97%" }}
                              ></div>
                              {/* <div className="status">Until Next Stage</div> */}
                            </div>
                            {/* <Typography
                              sx={{
                                marginTop: "1rem",
                                marginBottom: "0.5rem",
                                px: "1rem",
                              }}
                            >
                              USDT Raised: $2,897,322.14 / $2,974,083
                            </Typography> */}
                            <Typography
                              sx={{ marginBottom: "0.5rem", px: "1rem" }}
                              style={{
                                textAlign: "start",
                                margin: "10px 92px",
                              }}
                            >
                              {/* Your purchased $USDC = {tokenPrice}{" "}<br/> */}
                              Coin price this round &nbsp; &nbsp;
                              &nbsp;:0.10USDT/USDC
                              <br />
                              Coin price next round &nbsp; &nbsp;:0.12USDT/USDC
                              <br />
                              Public list price &nbsp; &nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              :0.20USDT/USDC
                              <img
                                className="my-auto"
                                src="https://etuktuk.io/assets/images/svg-icons/info-icon.svg"
                              ></img>
                            </Typography>
                            {/* <Typography
                              sx={{ marginBottom: "0.5rem", px: "1rem" }}
                            >
                              Your stakeable $TUK = 0{" "}
                              <img
                                className="my-auto"
                                src="https://etuktuk.io/assets/images/svg-icons/info-icon.svg"
                              ></img>
                            </Typography> */}
                          </Box>
                          <Box className="px-3 swapArea">
                            {/* <Typography className="dashTitle" sx={{marginBottom:"0.5rem",px:"1rem"}}>
                          1 $TUK = $0.0295
                        </Typography>
                          <Box className="text-center button-div">
                            <Button>
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                              <g clip-path="url(#clip0_4240_60908)">
                              <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F3BA2F"/>
                              <path d="M12.116 14.404L16 10.52L19.886 14.406L22.146 12.146L16 6L9.856 12.144L12.116 14.404ZM6 16L8.26 13.74L10.52 16L8.26 18.26L6 16ZM12.116 17.596L16 21.48L19.886 17.594L22.146 19.853L16 26L9.856 19.856L9.853 19.853L12.116 17.596ZM21.48 16L23.74 13.74L26 16L23.74 18.26L21.48 16ZM18.292 15.998H18.294V16L16 18.294L13.709 16.004L13.705 16L13.709 15.997L14.11 15.595L14.305 15.4L16 13.706L18.293 15.999L18.292 15.998Z" fill="white"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_4240_60908">
                              <rect width="32" height="32" fill="white"/>
                              </clipPath>
                              </defs>
                              </svg>  BNB</Button>
                            <Button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <path d="M25 0C38.8065 0 50 11.1935 50 25C50 38.8065 38.806 50 25 50C11.194 50 0 38.8095 0 25C0 11.1905 11.192 0 25 0Z" fill="#53AE94"/>
                                <path d="M28.0854 21.6691V17.9501H36.5899V12.2836H13.4319V17.9501H21.9374V21.6661C15.0249 21.9836 9.82739 23.3526 9.82739 24.9926C9.82739 26.6326 15.0274 28.0016 21.9374 28.3211V40.2336H28.0874V28.3201C34.9874 28.0016 40.1744 26.6336 40.1744 24.9951C40.1744 23.3566 34.9874 21.9886 28.0874 21.6701M28.0874 27.3111V27.3081C27.9139 27.3191 27.0224 27.3726 25.0374 27.3726C23.4504 27.3726 22.3339 27.3276 21.9404 27.3071V27.3121C15.8334 27.0416 11.2749 25.9781 11.2749 24.7056C11.2749 23.4331 15.8339 22.3711 21.9404 22.1001V26.2526C22.3404 26.2801 23.4844 26.3476 25.0634 26.3476C26.9599 26.3476 27.9134 26.2686 28.0884 26.2526V22.1001C34.1834 22.3716 38.7314 23.4361 38.7314 24.7041C38.7314 25.9721 34.1814 27.0371 28.0884 27.3086" fill="white"/>
                                </svg>
                                USDT
                              </Button>
                            <Button>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                              <path d="M2.97656 8.50496H22.9766" stroke="#131b1b" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M6.97656 16.505H8.97656" stroke="#131b1b" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M11.4766 16.505H15.4766" stroke="#131b1b" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.41656 3.50496H18.5266C22.0866 3.50496 22.9766 4.38496 22.9766 7.89496V16.105C22.9766 19.615 22.0866 20.495 18.5366 20.495H7.41656C3.86656 20.505 2.97656 19.625 2.97656 16.115V7.89496C2.97656 4.38496 3.86656 3.50496 7.41656 3.50496Z" stroke="#131b1b" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>Card</Button>
                          </Box>
                          <Box className="swapSection">
                            <Box className="w-50">
                              <div style={{justifyContent:"space-between",display:"flex"}}>
                                <label>Max</label>
                                <label>Max</label>
                              </div>
                              <div style={{position:"relative"}}>
                                 <Input placeholder="0"></Input> 
                                 <svg className="input-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <g clip-path="url(#clip0_4240_60908)">
                                <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#F3BA2F"/>
                                <path d="M12.116 14.404L16 10.52L19.886 14.406L22.146 12.146L16 6L9.856 12.144L12.116 14.404ZM6 16L8.26 13.74L10.52 16L8.26 18.26L6 16ZM12.116 17.596L16 21.48L19.886 17.594L22.146 19.853L16 26L9.856 19.856L9.853 19.853L12.116 17.596ZM21.48 16L23.74 13.74L26 16L23.74 18.26L21.48 16ZM18.292 15.998H18.294V16L16 18.294L13.709 16.004L13.705 16L13.709 15.997L14.11 15.595L14.305 15.4L16 13.706L18.293 15.999L18.292 15.998Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_4240_60908">
                                <rect width="32" height="32" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                              </div>
                            </Box>
                            <Box className="w-50">
                              <div>
                                <label>$TUK you receive</label>
                              </div>
                              <div style={{position:"relative"}}>
                                 <Input placeholder="0"></Input> 
                                 <svg className="input-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <path d="M25 0C38.8065 0 50 11.1935 50 25C50 38.8065 38.806 50 25 50C11.194 50 0 38.8095 0 25C0 11.1905 11.192 0 25 0Z" fill="#53AE94"/>
                                <path d="M28.0854 21.6691V17.9501H36.5899V12.2836H13.4319V17.9501H21.9374V21.6661C15.0249 21.9836 9.82739 23.3526 9.82739 24.9926C9.82739 26.6326 15.0274 28.0016 21.9374 28.3211V40.2336H28.0874V28.3201C34.9874 28.0016 40.1744 26.6336 40.1744 24.9951C40.1744 23.3566 34.9874 21.9886 28.0874 21.6701M28.0874 27.3111V27.3081C27.9139 27.3191 27.0224 27.3726 25.0374 27.3726C23.4504 27.3726 22.3339 27.3276 21.9404 27.3071V27.3121C15.8334 27.0416 11.2749 25.9781 11.2749 24.7056C11.2749 23.4331 15.8339 22.3711 21.9404 22.1001V26.2526C22.3404 26.2801 23.4844 26.3476 25.0634 26.3476C26.9599 26.3476 27.9134 26.2686 28.0884 26.2526V22.1001C34.1834 22.3716 38.7314 23.4361 38.7314 24.7041C38.7314 25.9721 34.1814 27.0371 28.0884 27.3086" fill="white"/>
                                </svg>
                              </div>
                            </Box>
                          </Box> */}
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              sx={{ color: "white" }}
                            >
                              <Card
                                style={cardStyle}
                                sx={{
                                  p: theme.spacing(0.2),
                                  m: theme.spacing(0.2),
                                }}
                              >
                                <CardContent>
                                  <Stack spacing={1}>
                                    <Item sx={{ display: "contents" }}>
                                      {" "}
                                      <div style={{ color: "white" }}>
                                        <h3>Buy MERV-CF TOKENS</h3>
                                        <p style={{ color: "gray" }}>
                                          Join our community and buy your
                                          MERV-CF Coins now. You stake
                                          automatically and receive a <br />
                                          monthly reword in USDT. We are
                                          currently in audit phase, after the
                                          audit is completed we will go live.
                                        </p>
                                        <h3>How to buy your MERV-CF Tokens</h3>
                                        <p style={{ color: "gray" }}>
                                          Create an account, verify your
                                          email-address, go to your wallet, at
                                          finance select deposit.
                                          <br />
                                          Copy your wallet address or scan the
                                          qr-code. Deposit USDT in your wallet.
                                          Buy Tokens <br />
                                          in pre-sale stages we support USDT
                                          (ERC20).
                                        </p>
                                      </div>
                                    </Item>
                                    <Divider
                                      sx={{
                                        border:
                                          "1px solid rgba(255, 255, 255, 0.2)",
                                      }}
                                    />
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        flexDirection: {
                                          xs: "column",
                                          md: "row",
                                        },
                                      }}
                                    >
                                      {/* <Item> */}
                                      <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        sx={{ textAlign: "center" }}
                                      >
                                        {/* <FormControl fullWidth={true}>
                                        <InputLabel
                                          htmlFor="email"
                                          shrink={true}
                                          sx={{ color: "#FFFFFF" }}
                                        >
                                        Enter Coins
                                        </InputLabel>
                                        <TextField
                                        sx={{height:"26px !important"}}
                                          margin="normal"
                                          required
                                          id="coins"
                                          name="coins"
                                          value={amount}
                                          autoComplete="coins"
                                          autoFocus
                                          onChange={e => setAmount(e.target.value)}
                                          placeholder="Enter coins"
                                        />
                                      </FormControl> */}
                                        <Box className={classes.noOfTickets}>
                                          <Typography
                                            variant="h5"
                                            textAlign="center"
                                            sx={{ pt: "26px" }}
                                          >
                                            Select No. of tokens:
                                          </Typography>
                                          <Stack
                                            flexDirection="column"
                                            alignItems="center"
                                            sx={{ mt: "13px" }}
                                          >
                                            <ButtonGroup sx={{ mb: "11px" }}>
                                              <Button
                                                className={classes.substract}
                                                onClick={handleSubstractCount}
                                              >
                                                <RemoveIcon
                                                  sx={{ fontSize: "15px" }}
                                                />
                                              </Button>
                                              <Button
                                                className={
                                                  classes.minimumTicket
                                                }
                                                sx={{
                                                  borderRadius:
                                                    "10px !important",
                                                  color: "#ffff",
                                                  mx: "12px !important",
                                                }}
                                              >
                                                {" "}
                                                <span
                                                  style={{
                                                    color: "#ffff !important",
                                                  }}
                                                >
                                                  1
                                                </span>
                                              </Button>
                                              <Button
                                                className={classes.substract}
                                                onClick={handleCount}
                                              >
                                                <AddIcon
                                                  sx={{ fontSize: "15px" }}
                                                />
                                              </Button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                              <Button
                                                className={classes.substract}
                                                onClick={
                                                  handleTenSubstractCount
                                                }
                                              >
                                                <RemoveIcon
                                                  sx={{ fontSize: "15px" }}
                                                />
                                              </Button>
                                              <Button
                                                sx={{
                                                  borderRadius:
                                                    "10px !important",
                                                  mx: "12px !important",
                                                  background:
                                                    "#ffff !important",
                                                }}
                                                disabled
                                              >
                                                <span
                                                  style={{
                                                    color: "#ffff !important",
                                                  }}
                                                >
                                                  10
                                                </span>
                                              </Button>
                                              <Button
                                                className={classes.substract}
                                                onClick={handleTenCount}
                                              >
                                                <AddIcon
                                                  sx={{ fontSize: "15px" }}
                                                />
                                              </Button>
                                            </ButtonGroup>
                                          </Stack>
                                        </Box>
                                        {/* <Button
                                      type="submit"
                                      // fullWidth
                                      variant="contained"
                                      onClick={createCryptoPayment}
                                      sx={{ mt: 3, mb: 2 }}
                                    >
                                      Buy Coins
                                    </Button>
                                    <Button
                                      type="submit"
                                      // fullWidth
                                      variant="contained"
                                      onClick={createCryptoPayment}
                                      sx={{ mt: 3, mb: 2 }}
                                      disabled={isLoading}
                                    >
                                      Download
                                    </Button> */}
                                      </Grid>
                                      {/* </Item> */}

                                      {/* <Item> */}
                                      {/* <Grid xs={3} > */}
                                      {/* {" "} */}
                                      {/* <Button
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        onClick={createCryptoPayment}
                                        sx={{ mt: 3, mb: 2 }}
                                      >
                                        Buy Coins
                                      </Button>
                                    </Grid> */}
                                      {/* </Item> */}
                                      <Grid item xs={12} md={6}>
                                        <Grid
                                          container
                                          spacing={0}
                                          className={classes.ticketPrize}
                                        >
                                          <Grid item xs={8} md={8}>
                                            {" "}
                                            <Typography
                                              variant="subtitle2"
                                              textAlign="left"
                                            >
                                              Token Price{" "}
                                              <Box
                                                sx={{
                                                  fontSize: {
                                                    md: "20px",
                                                    xs: "10px",
                                                  },
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {" "}
                                                ( {formValues.no_of_token}{" "}
                                                Tokens * {tokenPrice} )
                                              </Box>{" "}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={4} md={4}>
                                            <Typography
                                              variant="h4"
                                              sx={{ fontWeight: "700" }}
                                              textAlign="right"
                                            >
                                              {tokenPrice}
                                              {/* <CurrencyFormat
                                            value={amount}
                                            thousandSeparator={"."}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                          />{" "} */}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} md={12}>
                                            {" "}
                                            <Divider
                                              sx={{
                                                background:
                                                  "rgba(43, 44, 68, 0.5)",
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={6} md={6}>
                                            <Typography
                                              variant="subtitle2"
                                              textAlign="left"
                                              sx={{ fontWeight: "700" }}
                                            >
                                              Total
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={6} md={6}>
                                            <Typography
                                              variant="h4"
                                              sx={{ fontWeight: "700" }}
                                              textAlign="right"
                                            >
                                              {(
                                                Math.round(
                                                  amount * tokenPrice * 100
                                                ) / 100
                                              ).toFixed(2)}
                                              {/* <CurrencyFormat
                                            value={amount * tokenPrice} 
                                            thousandSeparator={"."}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                          /> */}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {/* 
                                  <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
                                    <Link
                                      style={{ color: "white", textDecoration: "none" }}
                                      to={file}
                                      target="_blank"
                                      download
                                    >
                                      {" "}
                                      <Button
                                        variant="contained"
                                        sx={{
                                          mt: { md: 2, xs: 2 },
                                          mb: { md: 2, xs: 10 },
                                          p: { md: "14px 28px", xs: "10px 10px" },
                                        }}
                                        type="submit"
                                        disabled={isLoading}
                                      // onClick={()=>{
                                      // }}
                                      >
                                        Download
                                      </Button>
                                    </Link>
                                  </Grid> */}
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        sx={{
                                          textAlign: "center",
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Button
                                          type="submit"
                                          style={{ padding: "18px 18px" }}
                                          // fullWidth
                                          variant="contained"
                                          onClick={(e) =>
                                            createCryptoPayment(e)
                                          }
                                          sx={{ mt: 3, mb: 2 }}
                                        >
                                          Buy Coins
                                        </Button>
                                        <Button
                                          type="submit"
                                          // fullWidth
                                          variant="contained"
                                          onClick={() =>
                                            handleDownload(
                                              file,
                                              "Crypto-Lottery-ICO-Whitepaper.pdf"
                                            )
                                          }
                                          style={{ padding: "18px 18px" }}
                                          sx={{ mt: 3, mb: 2 }}
                                          disabled={loading}
                                        >
                                          Download
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </CardContent>
                                {/* {loading && <>{}</>}{" "} */}
                              </Card>
                            </Grid>
                            {/*    <Box>
                            <Button className="btn-connect">
                            Buy Coins
                            </Button>
                            <Button className="btn-buy">
                            Download
                            </Button>
                          </Box>
                           */}
                          </Box>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12} sm={10} md={6} lg={6} xl={6} sx={{color:"white"}}>
                    <BuyCoin />

                    </Grid> */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{ color: "white" }}
                      >
                        <Grid
                          container
                          display={"flex"}
                          direction={"row"}
                          spacing={2}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{ marginBottom: "40px", p: theme.spacing(1) }}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                              variant="h2"
                              // align="center"
                              sx={{ mb: 2 }}
                            >
                              Summary
                            </Typography>
                            <Card
                              sx={{
                                margin: "0 auto",
                                background: "none",
                                display: "flex",
                                color: "white !important",
                                opacity: "0.7",
                              }}
                            >
                              <div
                                style={{
                                  columnCount: 2,
                                  columnFill: "balance",
                                  columnGap: "40px",
                                }}
                              >
                                The Wheel of Fortune, a game invented by MERV
                                GRIFFIN in 1975, is one of the most popular
                                games in the world. <br />
                                <br /> Licensing our products will open the
                                market of Fiat players. This will increase the
                                total revenue of the platform even more.
                                <br />
                                <br /> We have transformed this game into a new
                                block- chain based lottery system with the
                                highest win chance in the world.To give everyone
                                in the world the chance to play in the MERV
                                CRYPTO FORTUNE lottery we will start a global
                                marketing campaign to attract emerging markets
                                like South America, Asia and Africa.
                                <br />
                                <br />
                                <b>Early investors</b>
                                <br />
                                <br /> Early investors and players will be able
                                to buy and receive a 75% stake of the companies
                                revenue. To create a fair and public lottery, we
                                use block- chain technology. Lottery tickets are
                                sold as NFT’s in smart contracts and paid out in
                                crypto-currency.25% will be blocked and staked
                                to build up the power ball Jackpot. This tokens
                                will never go on the market.
                                <br />
                                <br /> Randomization of ticket-numbers is
                                realized through the RANDAO protocol introduced
                                by Ethereum2.Our goal is to have a monthly draw
                                in the first year. A total of 6 million tickets
                                will be sold, resulting in an annual return of
                                7.4% for the MERVCF token holders.
                                <br />
                                <br /> Players can influence the variables of
                                the lottery. All players specify their preferred
                                variables for the lottery, the average outcome
                                of all the players will be the final variables
                                for that particular lottery. This assures
                                transparency and fair play for all players.
                                 <br />
                                <br />
                                <b>Global aspirations</b>
                                <br />
                                <br /> MERV CRYPTO FORTUNE LOTTERY is a
                                community-based crypto lottery that offers their
                                players the best online gaming experience
                                possible! At MERV CRYPTO FORTUNE LOTTERY, we are
                                convinced that blockchain will change the online
                                gambling industry forever! To reach our global
                                aspirations we will keep on developing. We plan
                                to embed Merv Crypto Fortune Lottery to a
                                Gambling platform where players can also play in
                                an online Crypto Casino and a new and exiting
                                Egaming betting platform. This will attract more
                                players and will increase demand on the MERVCF
                                tokens.
                                <br />
                                <br />
                                With a weekly lottery of 500.000 tickets
                                creating a prizepool of 1.000.000 USDT will
                                result in a revenue of 26% on the MERVCF tokens.
                                <br />
                                <br />
                                Due to the limited number of tickets per draw,
                                many draws can potentially be held per week with
                                a very high return on an annual basis.
                                <br />
                                <br />
                                Early investors can buy the MERVCF token in pre-
                                sale for 0.10 USDT. Public sale will start at
                                0.20 USDT.
                                <br />
                                <br />
                                Early investors will receive their tokens paid
                                out in 10 equal installments per month. Private
                                investors can market up to 8% of their tokens
                                per year. This proves the confidence of early
                                investors in the success of the MERV crypto
                                fortune lottery. Some people call crypto a
                                gamble, let’s gamble with crypto!
                                <br />
                                {/* <br /> Our goal is to take 0.0001% of the market
                                in the first year. This means that 15 million
                                tickets will be sold resulting in an annual
                                revenue of 7.5% With a weekly lottery of 500.000
                                USDT this will result in a revenue of 13% on the
                                MERV crypto tokens.
                                <br />
                                <br /> Early investors can buy the MERV-Coin for
                                0.08 USDT. Public sale will start at 0.10 USDT */}
                                {/* <img src={"https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/"+DataValue?.data?.[0]?.image} alt="donation image"/> */}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  maxWidth: "920px",
                                  height: "auto",
                                  // margin: "50px",
                                  overflow: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    maxWidth: "700px",
                                    color: "white",
                                    textAlign: "start",
                                  }}
                                >
                                  <h1
                                    style={{
                                      fontSize: "23px",
                                      fontWeight: 500,
                                      color: "#3C37FF",
                                      marginTop: "0",
                                    }}
                                  ></h1>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      opacity: "0.7",
                                      fontWeight: 400,
                                      lineHeight: "150%",
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <Box className={classes.innerGridThird}></Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
