import { Close } from "@mui/icons-material";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Grid,
  Link,
  Button,
} from "@mui/material";
import { CustomModel } from "@/components/Elements/custom-modal";
import openMail from "@/assets/closedMail.svg";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { resendOTP, verifyOTPWithOTP } from "@/features/auth/api/verifyOTP";
import { useAuth } from "@/lib/auth";
import closeModal from "@/assets/closeModal.svg";
import classNames from "classnames";
import storage from "@/utils/storage";
import { getWalletAmount } from "../Elements/api/getWalletamount";
import { useNavigate } from "react-router-dom";
interface verifyOTPProps {
  open: boolean;
  onClose: () => void;
  email: string;
  onSuccess:() => void;
}

type verifyOTPValues = {
  otp: "";
  email: "";
};

const initialValues: verifyOTPValues = {
  otp: "",
  email: "",
};

const VerifyOTP = ({ open, onClose, email,onSuccess }: verifyOTPProps) => {
  const [otp, setOtp] = useState("");
  const { register } = useAuth();
  const navigate = useNavigate();
  const handleContinue = async(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const data = { otp: otp, email: email };
    console.log("jjjj")
    register(data);
    console.log("lll")
   console.log("storage.getToken()",storage.getToken())

    // if (storage.getToken()) {
    //   const dataWallet = await getWalletAmount()
    //   console.log("ddd",dataWallet?.data?.walletAmount);
    //   console.log("sum",dataWallet?.data?.walletAmount===0);
    //   if(dataWallet?.data?.walletAmount===0){
    //     console.log("data sum")
 
    //   }
    //   else{
    //     console.log("data sum 3")
    //     navigate("/app");
    //   }
    //   }
      onSuccess();
      navigate("/app/wallet/MyLottery");
      console.log("ffff")
    //setisOpen(true);
  };

  const handleResend = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const data = { email: email };
    open = true;
    await resendOTP(data);
    open = true;
  };

  return (
    <CustomModel
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Stack direction="row" height="100%" sx={{ p: 0 }}>
            <Box
              sx={{ width: "100%", overflow: "auto", mt: {md:"50px", xs:"20px"}}}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={openMail}
                alt="mailIcon"
                style={{ textAlign: "center", display:"block" }}
                width="60px"
                height="60px"
                className="image-otp"
              />

              <Typography
                component="h4"
                variant="h4"
                sx={{ mt: {md:"20px", xs:"7px"}, textAlign: "center" }}
              >
                Check your Email
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                sx={{ textAlign: "center", mt: {xs:"5px",md:"15px"}, mb: "20px" , ml:{xs:"15px", md:"0px"}}}
              >
                We sent a verification code to <br/> <span style={{color:"#3C37FF"}}>{email}</span>
              </Typography>
              <OtpInput
                value={otp}
                onChange={setOtp}
              numInputs={4}
              
                renderSeparator={<span style={{ marginRight: "10px" }}></span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={"input-otp"}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ textAlign: "center", mt: {xs:"25px", md:"40px" }}}
                  >
                    Didn't receive the email?
                   <Button>
                     <span  style={{ "textTransform":"capitalize" }} onClick={handleResend}>Resend</span> 
                    </Button>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{
                    mt: {xs:1, md:3},
                    mb: {xs:"30px", md:"45px"},
                    ml:{xs:"137px", md:"0px"},
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button variant="contained" onClick={handleContinue} sx={{px:{md:"24px !important", xs:"10px !important"}}}>
                    Verify
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <IconButton sx={{padding:{md:"25px", xs:"10px"}}}onClick={onClose}>
                <img src ={closeModal}/>
              </IconButton>
            </Box>
          </Stack>
        </>
      }
    />
  );
};

export default VerifyOTP;
