import React, { useState } from 'react';
import { Box, Typography, Grid, } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UploadIcon from '@mui/icons-material/Upload';
import Checkbox from '@mui/material/Checkbox';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DatePicker from "react-date-picker"
import "react-datepicker/dist/react-datepicker.css";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
    flexWrap: {
        flexWrap: "wrap"
    },
    dFlex: {
        display: "flex",
    },
    justifyEnd: {
        justifyContent: "end"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyBetween: {
        justifyContent: "space-between"
    },
    container: {
        margin: "20px 82px",
        [theme.breakpoints.down('lg')]: {
            margin: "0px 0px",
          },
    },
    heading1: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20.7536px",
        lineHeight: "24px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.315455px",
        color: "#FFFFFF"
    },
    para1: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.38px",
        textTransform: "uppercase",
        color: "#FFFFFF",
        opacity: 0.5,
        marginTop: "15px",
        marginBottom: "5px"
    },
    blueColor: {
        color: "#3C37FF !Important",
    },
    heading2: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "25px",
        lineHeight: "140%",
        color: "#FFFFFF"
    },
    para2: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "150%",
        color: "#FFFFFF",
        opacity: 0.6,
        marginTop: "28px"
    },
    darkBlue: {
        background: "#050518",
        padding: "60px",
        borderRadius: "10px"
    },
    width50: {
        width: "50%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
          },
    },
    width60: {
        width: "60%"
    },
    heading3: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase"
    },
    para3: {
        fontFamily: "'Lato'",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 0.6)",
        opacity: 0.9,
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: "2px",
        // [theme.breakpoints.down('sm')]: {
        //     height: "10px",
        //     width: "50%",
        //   },
    },
    para4: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        display: "flex",
        alignItems: "center",
        color: "rgba(255, 252, 252, 0.6)",
    },
    myProfile: {
        background: "rgba(5, 5, 24, 0.8)",
        mixBlendMode: "normal",
        borderRadius: "10px",
        width: "50%",
        padding: "30px"
    },
    profileEditButton: {
        background: "transparent",
        border: "none",
        position: "relative",
        left: "-35px"
    },
    blueButton: {
        padding: "17px 16px",
        background: "#3C37FF",
        borderRadius: "10px",
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        border: "none",
        marginTop: "37px",
        width: "132px"
    },
    ChangePassword: {
        background: "rgba(5, 5, 24, 0.8)",
        mixBlendMode: "normal",
        borderRadius: "10px",
        width: "30%",
        padding: "30px",
        marginLeft: "30px",
        height: "100%"
    },
    cancleButton: {
        padding: "17px 16px",
        borderRadius: "10px",
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        marginTop: "37px",
        width: "110px",
        background: "transparent",
        border: "1px solid white",
        marginLeft: "10px"
    },
    myFormContainer: {
        padding: "50px",
        background: "#050518",
    },
    nameOfApplicant: {
        background: "#040414",
        mixBlendMode: "normal",
        border: "1px solid #1F1F1F",
        borderRadius: "10px",
        marginTop: "30px",
        padding: "20px"
    },
    date: {
        background : "transparent" ,
        color : "rgba(255, 255, 255, 0.4)",
        border: "0.748225px solid rgba(255, 255, 255, 0.4)",
        borderRadius: "7.48225px",
        width: "100%",
        padding: "17px 0px",       
    },
    width100 : {
        width : "100%",
        // [theme.breakpoints.down('lg')]: {
        //     width : "165px"
        //   },
    }
}));

function KYCform() {
    const classes = useStyles();

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const ChooseFile = () => {
        document.getElementById('getFile')?.click();
    }
    const [startDate, setStartDate] = useState(new Date());
    
    return (
        <>
            <Box className={` ${classes.dFlex} ${classes.justifyCenter}`}>
                <Box className={`${classes.width50} ${classes.myFormContainer}`}>
                    <Box className={`${classes.dFlex} ${classes.justifyEnd}`}>
                        <CancelOutlinedIcon style={{ color: "#FFFEEC" }} />
                    </Box>
                    <Typography className={classes.heading1}>KYC Form</Typography>
                    <Typography className={classes.para1}>Name of Applicant</Typography>
                    <hr style={{ marginBottom: "40px" }} />
                    <Grid container spacing={1} className={`${classes.dFlex} ${classes.flexWrap}`} style={{ marginBottom: "20px" }}>
                        <Grid md={4} xs={12}>
                            <Typography className={classes.para3}>First Name</Typography>
                            <TextField
                                className={classes.width100}
                                id="outlined-multiline-flexible"
                                placeholder='Enter your name'
                                multiline       
                                maxRows={4}
                            />
                        </Grid>
                        <Grid md={4} xs={12}>
                            <Typography className={classes.para3}>Last Name</Typography>
                            <TextField
                                className={classes.width100}
                                id="outlined-multiline-flexible"
                                placeholder='Enter your last name'
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                        <Grid md={4} xs={12}>
                            <Typography className={classes.para3}>Nick Name</Typography>
                            <TextField
                                className={classes.width100}
                                id="outlined-multiline-flexible"
                                placeholder='Enter your nick name'
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className={classes.dFlex}>
                        <Grid sm={6} xs={12}>
                            <Typography className={classes.para3}>Email Address</Typography>
                            <TextField
                                style={{ width: "100%" }}
                                id="outlined-multiline-flexible"
                                placeholder='Enter Email Address'
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                        <Grid sm={6} xs={12}>
                            <Typography className={classes.para3}>Country</Typography>
                            <FormControl style={{ width: "100%" }}>
                                <Select
                                    value={age}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="">
                                        <em >Select Country </em>
                                    </MenuItem>
                                    <MenuItem value={10} style={{ color: "black" }}>Withdraw</MenuItem>
                                    <MenuItem value={20} style={{ color: "black" }}>Twenty</MenuItem>
                                    <MenuItem value={30} style={{ color: "black" }}>Thirty</MenuItem>
                                </Select>
                                {/* <FormHelperText>Without label</FormHelperText> */}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography className={classes.para3}>Select Document</Typography>
                    <FormControl style={{ width: "100%" }}>
                        <Select
                            value={age}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="">
                                <em >ID Proof</em>
                            </MenuItem>
                            <MenuItem value={10} style={{ color: "black" }}>Withdraw</MenuItem>
                            <MenuItem value={20} style={{ color: "black" }}>Twenty</MenuItem>
                            <MenuItem value={30} style={{ color: "black" }}>Thirty</MenuItem>
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                    </FormControl>
                    <Box className={classes.dFlex} mt={2}>
                        <input type="file" id='getFile' hidden />
                        <UploadIcon className={classes.blueColor} />
                        <button className={classes.blueColor} style={{ background: "transparent", border: "none" }} onClick={() => { ChooseFile() }}>Upload Files</button>
                    </Box>
                    <Typography className={classes.para3}>Comments (If Required)</Typography>
                    <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        placeholder='Write Comments'
                        multiline
                        maxRows={4}
                        rows={5}
                    />
                    <Box className={classes.dFlex}>
                        <Checkbox {...label} defaultChecked style={{ color: "white" }} />
                        <Typography className={classes.para2}>I hereby declare that the information provided in this form is accurate and complete. I confirm that any information is found incorrect and/or incomplete that leads a violation of regulations may initiate legal actions, and I accept that I am the responsible party for any and all charges, penalties and violations.</Typography>
                    </Box>
                    <Box className={classes.nameOfApplicant}>
                        <Typography className={classes.para1}>Name of the Applicant</Typography>
                        <Grid container spacing={1} className={classes.dFlex} style={{ marginBottom: "20px", marginLeft: "0px", marginTop: "10px" }}>
                            <Grid md={4} xs={12}>
                                <Typography className={classes.para3}>First Name</Typography>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="outlined-multiline-flexible"
                                    placeholder='Enter your name'
                                    multiline
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid md={4} xs={12}>
                                <Typography className={classes.para3}>Last Name</Typography>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="outlined-multiline-flexible"
                                    placeholder='Enter your last name'
                                    multiline
                                    maxRows={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} className={classes.dFlex} style={{ marginBottom: "20px", marginLeft: "0px", marginTop: "10px" }}>
                            <Grid md={4} xs={12}>
                                <Typography className={classes.para3}>Signature of the Applicant</Typography>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="outlined-multiline-flexible"
                                    placeholder=''
                                    multiline
                                    maxRows={4}
                                />
                            </Grid>
                            <Grid md={4} xs={12}>
                                <Typography className={classes.para3}>Date Signed</Typography>
                                <DatePicker className={classes.date}/>
                            </Grid>
                        </Grid>
                        <Typography className={classes.blueColor}>Clear</Typography>
                    </Box>
                    <button className={classes.blueButton}>Save Now</button>
                </Box>
            </Box>
        </>
    )
}

export default KYCform