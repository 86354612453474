import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '@/lib/react-query';
// import { contactList } from '../types';
import { useMutation } from 'react-query';

export type postProfileDTO = {
    firstName: string,
    lastName: string,
    nickName: string,
    email: string,
    country: string,
    city:string,
    state:string,
    address: string,
    zipCode: number,
    image: string,
  
  };
  

export const postProfile = async (payload: any)=> {
  const response = await axios.patch(`/api/v1/user/profile`, payload)
   const data = response.data;
  return data;
};

type UseProfileListOptions = {
    config?: MutationConfig<typeof postProfile>;
  };

export const useProfileList = ({  config }: UseProfileListOptions) => {
    return useMutation({
      ...config,
      mutationFn: postProfile,
    });
};


