import * as React from "react";
import { Paper, CssBaseline, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import { CrouselComponent } from "@/components/Elements/crousel";

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  const theme = useTheme();
  const hidden = useMediaQuery('(min-width: 567px)');
  const hiddenDesktop = useMediaQuery('(max-width: 568px)');
  return (
    <>
      <Grid
        container
        component="main"
        sx={{ backgroundColor: "#050518", p: {xs:0, md:1}, height: "100vh" }}
      >
        <CssBaseline />
        {hidden?<CrouselComponent/>:""}
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          lg={8}
          xl={7}
          component={Paper}
          sx={{
            backgroundColor: "#050518",
            color: "#FFFFFF",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            p:{xs:theme.spacing(0.1), md:theme.spacing(0.62)},
            
          }}
        >
          <Box
            sx={{
              my: 1,
              mx: 1,
            }}
          >
            {children}
          </Box>
          {hiddenDesktop?<CrouselComponent/>:""}
        </Grid>
      </Grid>
    </>
  );
};
