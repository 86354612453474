import React, { useEffect, useState } from "react";
import {
  Badge,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  DialogActions,
  Button,
  Menu,
  Typography,
  Link,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { IconMenu } from "@tabler/icons-react";
import axios from "axios";
import storage from "@/utils/storage";
import { deleteNotification } from "./api/deleteNotification";
import { deleteAllNotification } from "./api/deleteAllNotification";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  disableRipple: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const NotificationPopup = () => {
  const classes = useStyles();
  const [notificationCount, setNotificationCount] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState([{ subject: "" }]);
  const open = Boolean(anchorEl);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    let token = storage.getToken();
    await axios
      .get(`${process.env.API_URL_PREFIX}/api/v1/user/notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
 
        setMenuItems(res.data.data);
        setNotificationCount(res.data.count);
      })

      .catch((err) => {
   
      });
  };
  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    let token = storage.getToken();
    await axios
      .get(`${process.env.API_URL_PREFIX}/api/v1/user/notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
      
        setMenuItems(res.data.data);
        setNotificationCount(res.data.count);
        //setWinnerArray(res.data.data.lotteryDrawTktNum);
        countApi();
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const countApi = async () => {
    let token = storage.getToken();
    await axios
      .put(
        `${process.env.API_URL_PREFIX}/api/v1/user/readNotification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setNotificationCount((prev) => 0);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handleNotificationClick = () => {
    // Show the popup dialog when the notification icon is clicked
    setPopupOpen(true);
  };

  const handleDeleteNotification = (id) => {
    Swal.fire({
      title: "Notifications",
      text: "Are you sure you want to delete this notification?",
      icon: "warning",
      showCancelButton: true,
      background: "black",
      color: "white",
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the deletion, call the deleteNotification function
        deleteNotification(id)
          .then(() => {
            // Perform any additional actions after successful deletion, if needed.
            // For example, you could update the notification list after deletion.
            // setNotificationList(updatedNotificationList);
          })
          .catch((error) => {
            console.error("Error deleting notification:", error);
          });
      }
    });
  };

  const handleClearAll = () => {
    Swal.fire({
      title: "Notifications",
      text: "Are you sure you want to delete all notifications?",
      icon: "warning",
      background: "black",
      showCancelButton: true,
      color: "white",
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the deletion, call the deleteNotification function
        deleteAllNotification()
          .then(() => {
            // Perform any additional actions after successful deletion, if needed.
            // For example, you could update the notification list after deletion.
            // setNotificationList(updatedNotificationList);
            Swal.fire({
              background: "black",
              color: "#ffff",
              icon: "success",
              title: "Success!",
              text: "All Notifications deleted!!",
            });
          })
          .catch((error) => {
            console.error("Error deleting notification:", error);
          });
      }
    });
  };

  const handleClosePopup = () => {
    // Close the popup dialog when the "Close" button is clicked
    setPopupOpen(false);
  };

  return (
    <div>
      <Tooltip title="Notifications">
        <Badge
          badgeContent={notificationCount > 0 ? notificationCount : 0}
          color="secondary"
          sx={{
            "& .MuiBadge-badge": {
              top: "7px",
              right: "9px",
              fontSize: "0.7035714285714286rem",
              minWidth: "5px",
              backgroundColor: "red",
            },
          }}
        >
          <IconButton
            color="inherit"
            disableRipple={true}
            className={classes.disableRipple}
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <NotificationsIcon
              sx={{ color: "#fff" }}
              className={classes.disableRipple}
            />
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            fontSize: "12px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              maxHeight: "300px", // Set the desired maximum height
              overflowY: "auto", // Enable vertical scrolling
            },
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundImage:
                "linear-gradient(180deg, #3b97bb 0%, #aed1db 99%)",
              boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
              borderRadius: "100px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
           
          </ListItemIcon>
          Logout
        </MenuItem> */}
        {menuItems?.length > 0 ? (
          <MenuItem onClick={handleClearAll} sx={{ justifyContent: "center" }}>
            Clear All
          </MenuItem>
        ) : (
          <MenuItem onClick={handleClearAll} sx={{ justifyContent: "center" }}>
            No Notifications Found
          </MenuItem>
        )}
        {menuItems?.length > 0
          ? menuItems.map((value: any, index: any) => (
              <MenuItem key={index} onClick={handleClose}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <Typography variant="h3" sx={{ fontSize: "14px" }}>
                  {value?.subject === "New lottery added" ? (
                    <Link
                      key={value?.subject}
                      variant="h3"
                      color="text.primary"
                      href={"/app/wallet/MyLottery"}
                      underline="none"
                      onClick={() =>
                        window.location.assign("/app/wallet/MyLottery")
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{ flexGrow: 1, color: "#FFBC00" }}
                        >
                          {value?.subject}
                        </Typography>
                        {/* Date on the Right */}
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            flexShrink: 0,
                            color: "white",
                            position: "absolute",
                            right: "10px",
                            "font-size": "13px",
                          }}
                        >
                          {
                            new Date(value?.createdAt)
                              ?.toISOString()
                              .split("T")[0]
                          }{" "}
                          {new Date(value?.createdAt)
                            ?.toISOString()
                            .split("T")[1]
                            .substring(0, 5)}
                        </Typography>
                      </div>{" "}
                    </Link>
                  ) : (
                    <Link
                      key={value?.subject}
                      variant="h3"
                      color="text.primary"
                      href={"/app/upComingLotteries"}
                      underline="none"
                      onClick={() =>
                        window.location.assign("/app/upComingLotteries")
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{ flexGrow: 1, color: "#FFBC00" }}
                        >
                          {value?.subject}
                        </Typography>
                        {/* Date on the Right */}
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            flexShrink: 0,
                            color: "white",
                            position: "absolute",
                            right: "10px",
                            "font-size": "13px",
                          }}
                        >
                          {value?.createdAt
                            ? new Date(value?.createdAt)
                                ?.toISOString()
                                .split("T")[0]
                            : ""}{" "}
                          {value?.createdAt
                            ? new Date(value?.createdAt)
                                ?.toISOString()
                                .split("T")[1]
                                .substring(0, 5)
                            : ""}
                        </Typography>
                      </div>{" "}
                    </Link>
                  )}

                  <br />
                  <span style={{ color: "#FFFFFFCC", cursor: "default" }}>
                    {value?.description}
                  </span>
                </Typography>
                <br />
                <ListItemIcon
                  onClick={() => handleDeleteNotification(value?._id)}
                >
                  {/* Add the DeleteIcon for each menu item */}
                  <DeleteIcon
                    fontSize="small"
                    sx={{
                      color: "blue",
                      position: "absolute",
                      right: "5px",
                      cursor: "pointer",
                    }}
                  />
                </ListItemIcon>
              </MenuItem>
            ))
          : ""}
      </Menu>
    </div>
  );
};

export default NotificationPopup;
