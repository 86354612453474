import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { allData } from "./constants";
import storage from "@/utils/storage";
import axios from "axios";
import "./myWinning.css";
import Swal from "sweetalert2";
import { Button, Typography } from "@mui/material";
import { Opacity } from "@material-ui/icons";
import MervIcon from "@/assets/mervIcon.svg";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from 'react-currency-format';
const MyWinningList = () => {
  const countPerPage = 10;
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(10);
  const [data, setData] = useState([
    {
      LotteryName: "",
      ticketNumber: "",
      winAmount: 0,
      claimStatus: "",
      lotteryStatus: "",
      amount: "",
      surpriceAmt: 0,
      createdDate: "",
      createdTime: "",
      claimRemark: "",
      transactionId: "",
    },
  ]);
  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );
  const [isLoading, setIsLoading] = useState(false);

  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );
  const column = Object.keys(data[0]);
const [showLoader,setShowLoader]=useState(false)
  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  useEffect(() => {
    getData(value, 1);
  }, [value]);
  console.log("value win", value);
  console.log("data Winning", data);
  const redeemPrize = async (id: any, amount: any) => {
    try {
      let token = storage.getToken();
      let payload = {
        amount: amount,
      };
      setShowLoader(true);
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/redeemSupriceInWallet/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      Swal.fire({
        background: "black",
        color: "#ffff",
        icon: "success",
        title: "Success!",
        text: "The prize of a free ticket is added to your wallet",
      }).then((res) => {
        setShowLoader(false);
        window.location.reload();
      });
    } catch (err) {
           setShowLoader(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        title: "Error!",
        text: "Oops something went wrong. Please try again later.",
      }).then((res) => {
        window.location.reload();
      });
    }
  };

  const winAmountClaim = async (id: any, winAmount: any) => {
    try {
      let token = storage.getToken();
      let payload = {
        transactionId: id,
        amount: winAmount,
      };
      setIsLoading(true);
      const res = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/winAmountClaim`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      Swal.fire({
        background: "black",
        color: "#ffff",
        icon: "success",
        title: "Success!",
        text: res?.data?.message,
      });
      setIsLoading(false);
    } catch (err: any) {
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: err?.response.data?.message,
      });
    }
  };
  const getData = async (value, p) => {
    let token = storage.getToken();
    let pageLimit;
    pageLimit = value != "" ? totalRecords : 10;
    const payable = {
      page: p,
      limit: pageLimit,
    };
    await axios
      .post(
        `${process.env.API_URL_PREFIX}/api/v1/user/myWinning?search=${value}`,
        payable,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data.data);
        setTotalRecords(res.data.winningPageCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
    getData(value, p);
  };
  console.log("?search=", value);
  console.log("Redeem Loader",showLoader );
  return (
    <>
      <div style={{overflow:"auto"}}>
        <input
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="search"
        />
             <Typography
                    variant="h5"
                    sx={{
                      position: "absolute",
                      right: "10%",
                      margin: "-18px",
                      pt: { xs: "5px", md: "0" },
                    }}
                    style={{margin: "-58px 10px"}}
                  >
                    <img
                      src={MervIcon}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative",
                        top: "5px",
                      }}
                    />{" "}
                    {coins?.data?.data} <br />
                    <img
                      src={yellowt}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative",
                        top: "5px",
                        right: "5px",
                      }}
                    />
                    {/* {dataWallet?.data?.data?.walletAmount} */}
                    <CurrencyFormat value={dataWallet?.data?.data?.walletAmount} thousandSeparator={'.'} displayType={'text'} decimalSeparator={','} />
                  </Typography>
      </div>
      
      <div className="table-t1">
        <table style={{borderCollapse:"collapse", width:"100%"}}>
          <tr className="table-th">
            <th>Lottery Name</th>
            <th>Ticket Number</th>
            <th>Win Amount</th>
            <th>Claim</th>
            {/* <th>Claim Status</th> */}
            <th>Redeem</th>
            <th>Win Status</th>
            <th>Ticket Price</th>
            {/* <th>Free Ticket Amt</th> */}
            <th>CreatedAt</th>
            <th>Claim Remark</th>
          </tr>
          {data.map((item, i) => {
            return (
              <tr key={i} className="table-th2">
                <td>{item?.LotteryName}</td>
                <td>{item?.ticketNumber}</td>
                <td>{item?.winAmount}</td>
                {Number(item?.winAmount)>0 && item?.claimStatus!=="Approved" && item?.claimStatus!=="Pending"? 
               ( <td>
                {item?.claimStatus==="Rejected" ?
                  <button
                    onClick={() =>
                      winAmountClaim(item?.transactionId, item?.winAmount)
                    }
                    className="claim-reject-btn"
                  >
                    Rejected
                  </button>
                  :
                  <button
                  onClick={() =>
                    winAmountClaim(item?.transactionId, item?.winAmount)
                  }
                  className="claim-btn"
                >
                 Claim
                </button>
          }
                </td>)
          :(
            <td>
      
              
              {item?.claimStatus!=="Pending" ?   item?.claimStatus!=="Approved" ? 
                    <button
                    onClick={() =>
                      winAmountClaim(item?.transactionId, item?.winAmount)
                    }
                    className="claim-btn-disabled"
                    disabled
                  >
              Claim
              </button>
              :
              <button
              className="claim-btn-approve-disabled"
              disabled
              >
                Approved
                </button> 
              :
              <button
              className="claim-btn-process-disabled"
              disabled
              >
              Processing
              </button>
              }
          </td>
          )
            }
                {/* <td>{item.claimStatus}</td> */}
                {Number(item?.surpriceAmt)>0?
                (<td>
                  <button
                    onClick={() =>
                      redeemPrize(item?.transactionId, item?.surpriceAmt)
                    }
                    className="redeem-btn"
                    disabled={showLoader}
                  >
                   {showLoader? <div>Redeem</div>:<>Redeem</>}
                  </button>
                </td>) :(
                  <td>
                   <button
                   onClick={() =>
                     redeemPrize(item?.transactionId, item?.surpriceAmt)
                   }
                   className="redeem-btn-disabled"
                   disabled
                 >
                   Redeem
                 </button>
                 </td>
                )
            } 
                <td>{item?.lotteryStatus}</td>
                <td>{item?.amount}</td>
                {/* <td>{item?.surpriceAmt}</td> */}
                <td>{item?.createdDate}</td>
                <td>{item?.claimRemark}</td>
              </tr>
            );
          })}
        </table>
      </div>

      {value == "" ? (
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={totalRecords}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default MyWinningList;
