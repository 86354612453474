import { useNavigate } from 'react-router-dom';
import HomeScreen from '../Components/homeScreen';
import HeaderMain from '@/components/Elements/headerMain';
import Footer from '@/components/Elements/footer';
import { Box } from '@mui/material';
import HomeScreenImg from "@/assets/earth.jpg";
import NewHomeScreenImg from "@/assets/earth.jpg";




export const HomeScreenMain = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{
  background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${NewHomeScreenImg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  minHeight: "100%",
  backgroundAttachment: "fixed",
backgroundPosition: "center",
    }}>
    <HeaderMain />
      <HomeScreen />
      <Footer />
      </Box>
  );
};