import { AppProvider } from '@/providers/app';
import {AppRoutes}  from '@/routes';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './lib/auth';
import '@sweetalert2/theme-material-ui';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useMediaQuery } from '@mui/material';


function App() {
  
  const [open, setOpen] = React.useState(false);
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
  const isLandscape = useMediaQuery('(orientation: landscape)');
                                                                                     
  const handleOrientationChange = () => {
    if (isMobileDevice && !isLandscape) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    handleOrientationChange(); // Call the function on initial load

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);
  return (
    <AppProvider>
      <AppRoutes />
      <ToastContainer />
      <Dialog  open={open} onClose={handleClose}>
        <DialogContent sx={{background:"black"}}>
          <DialogContentText sx={{color:"white", textAlign:"center"}}>
          This website is only viewable in landscape mode. 
          <br></br>Please turn your phone and adjust your keyboard settings.
          </DialogContentText>
        </DialogContent>
        <DialogActions  sx={{background:"black"}}>
          <Button  variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppProvider>
  );
}

export default App;          



