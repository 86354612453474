import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Divider, Grid, LinearProgress, Link, Paper, Slider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material';
import clsx from 'clsx';
import { TopHeadingLayout } from '@/components/layout/TopHeadingLayout';
import promotionsBackground from "@/assets/promotionsBackground.png";
import promotionCard from "@/assets/promotionCard.svg";
import lotteryspinner from '@/assets/lotteryspinner.svg'
import { theme } from '@/components/Elements/theme';
import { url } from 'inspector';
import yellowt from "@/assets/yellow-t.png";
import "../../../../src/components/Elements/CSS/style.css"
import whitet from "@/assets/white-t.svg";


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px"
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: '30px',
    borderRadius: '20px',
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: '10px',
    borderRadius: '20px',
  },
  innerGridFirst: {
    background: `url(${lotteryspinner})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: 'left bottom',
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: '10px',
    borderRadius: '20px',
  },
  innerGridThird: {
    background: `url(${lotteryspinner})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: 'right bottom',
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  }
}));

const cardData = [
  { title: 'Biggest Welcome Cashback2Go!', description: 'Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!', media: promotionCard },
  { title: 'Biggest Welcome Cashback2Go!', description: 'Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!', media: promotionCard },
  { title: 'Biggest Welcome Cashback2Go!', description: 'Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!', media: promotionCard },
  { title: 'Biggest Welcome Cashback2Go!', description: 'Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!', media: promotionCard },
  { title: 'Biggest Welcome Cashback2Go!', description: 'Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!', media: promotionCard },
  { title: 'Biggest Welcome Cashback2Go!', description: 'Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!', media: promotionCard },
];




export default function WinningHistory({ dataRecent }) {
  const classes = useStyles();

  return (

    <>

      {dataRecent?.winLottery?.length > 0 ?
        <><Typography component="h4" variant="h4" sx={{
          marginLeft: "2px",
          marginBottom: "20px",
          marginTop: "40px",
        }}>
          Your winning tickets
        </Typography><Box sx={{ xs: theme.spacing(1), md: theme.spacing(0.3) }}>
            <Grid container spacing={3} justifyContent={"center"}>
              {dataRecent.winLottery.map((card: {
                ticketNumber: string;
                winAmount: string;
                rank: string;
                lotterName: string;
                lotteryImage: string | undefined;
                lotteryStatus: string;
              }, index: React.Key | null | undefined) => (
                <Grid item sx={{ maxWidth: { xs: "190px", md: "210px" } }} key={index}>
                  <Card classes="h-inherit" sx={{ maxWidth: { xs: "190px", md: "220px" }, cursor: "pointer !important", height: "100%" }} className={classes.cardRoot} onClick={() => { window.location.assign('/app/wallet/MyLottery') }}>
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        className='img-card'
                        sx={{
                          height: { xs: "150px", md: "182px" },
                          objectFit: "fill",
                          backgroundPosition: 'initial'
                        }}
                        component="img"
                        image={card.lotteryImage ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${card.lotteryImage}` : ''}
                        title="green iguana" />
                      {card?.lotteryStatus == "Winner+Free Ticket" || card?.lotteryStatus == "Free Ticket" ?
                        <Chip
                          label={"Free Ticket"}
                          sx={{
                            position: "absolute",
                            transform: "rotate(45deg)",
                            top: "7px",
                            right: "-42px",
                            backgroundColor: "blue",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                            padding: "8px 26px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            zIndex: 1,
                          }}
                        /> : null}
                    </div>

                    <Box sx={{ position: "relative", width: "100%" }}>
                      <Box sx={{
                        width: "82.22px",
                        height: "30.68px",
                        position: "absolute",
                        left: "41%",
                        margin: "-15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#FFFFFF",
                        borderRadius: "15.34px"
                      }}>   <Typography variant="caption" sx={{ color: "#3C37FF", fontWeight: "700", display: "flex" }}>
                          <img style={{
                            height: "auto",
                            marginTop: "auto", marginBottom: "auto",
                            width: "14px",
                            paddingRight: "4px"
                          }} src={yellowt} /> {card?.winAmount}
                        </Typography></Box></Box>

                    <CardContent sx={{ backgroundColor: "#3C37FF" }} className='h-inherit'>

                      <Grid container sx={{ p: "25" }}>
                        <Grid item xs={12}>
                          <Typography variant="caption" sx={{ color: "#FFFFFF", opacity: 0.5 }}>
                            Ticket Number
                          </Typography>
                          <Typography variant="h3" component="h3">
                            {card?.ticketNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider sx={{ border: "1px dashed rgba(255, 255, 255, 0.6)", marginTop: "20px" }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="caption" sx={{ color: "#FFFFFF", opacity: 0.5 }}>
                            Jackpot name
                          </Typography>
                          <Typography variant="h3" component="h3">
                            {card.lotterName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className='text-center d-flex flex-column'>
                          <Typography className='mx-auto' variant="caption" sx={{ color: "#FFFFFF", opacity: 0.5 }}>
                            Spin                  </Typography>
                          <Typography className='mx-auto' variant="h3" component="h3" sx={{
                            p: "5.74px ", borderRadius: "14px", background: "#3C37FF", textAlign: "center",
                            width: "20px",
                            marginLeft: "10px",
                          }}>
                            {card?.rank}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>

                  </Card></Grid>
              ))}
            </Grid>
          </Box></> : ""}
    </>
  );
}
