import React from 'react';
import HeaderMain from '@/components/Elements/headerMain';
import Footer from '@/components/Elements/footer';
import MyProfil from '../components/MyProfile';

export const MyProfile = () => {
    return (
        <div>
            {/* <HeaderMain /> */}
            <MyProfil/>
            <Footer />
        </div>
    )
}
