import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { lotteryTicketList } from '../types';

export const getLotteryTicket = ({ LotteryId }: { LotteryId: string }): Promise<lotteryTicketList> => {
  return axios.get(`/api/v1/user/lotteryTiketNumberList/${LotteryId}`);
};

type QueryFnType = typeof getLotteryTicket;

type UseLotteryTicketOptions = {
    LotteryId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useLotteryTicket = ({ LotteryId, config }: UseLotteryTicketOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['lotteryTicketList', LotteryId],
    queryFn: () => getLotteryTicket({ LotteryId }),
  });
};