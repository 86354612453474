import React, { useEffect } from 'react';
import { Box, Paper, Typography, Grid, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import rightAgleArrow from "../../../assets/rightAgleArrow.png";
import homescreenBackground from "../../../assets/homescreenBackground.png";
import logoCrypto from '@/assets/logoCrypto.svg';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useState } from "react";
import { getContactList } from '../api/contactus';
import { getAdminContact, useAdminContact } from '../api/adminContactUs';
// import { getContactList } from '../api/contactus';

const useStyles = makeStyles((theme) => ({
    flexWrap: {
        flexWrap: "wrap"
    },
    dFlex: {
        display: "flex",
    },
    justifyEnd: {
        justifyContent: "end"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyBetween: {
        justifyContent: "space-between"
    },
    heading1: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
        color: "#FFFCFC"
    },
    para1: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "130%",
        color: "#FFFFFF",
        opacity: 0.9,
        margin: "20px auto"
    },
    blueColor: {
        color: "#3C37FF !Important",
    },
    heading2: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "25px",
        lineHeight: "140%",
        color: "#FFFFFF"
    },
    para2: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "140%",
        display: "flex",
        alignItems: "center",
        color: "rgba(255, 252, 252, 0.6)",
        opacity: 0.6
    },
    breadcrum: {
        margin: "auto 84px",
        paddingTop: "20px",
        paddingBottom: "20px",
        [theme.breakpoints.down('md')]: {
            margin: "auto 5px",
        },
    },
    blueColorOpacity: {
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px",
        padding: "35px",
        [theme.breakpoints.down('xs')]: {
            padding: "10px",
        },
    },
    mainBox: {
        backgroundImage: `url(${homescreenBackground})`,
        backgroundRepeat: "no-repeat",

        "background-size": "cover"
    },
    darkBlue: {
        background: "#050518",
        padding: "60px",
        borderRadius: "10px",
        [theme.breakpoints.down('md')]: {
            padding: "20px",
            marginTop: "10px"
        },
    },
    width50: {
        width: "50%"
    },
    blueButton: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        textAlign: "center",
        color: "#FFFCFC",
        background: "#3C37FF",
        borderRadius: "9.15069px",
        border: "none",
        width: "100%",
        height: "50px",
        marginTop: "20px",
        marginBottom: "20px"
    },
    heading3: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase"
    },
    para3: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "150%",
        display: "flex",
        alignItems: "center",
        textDecorationLine: "underline"
    },
    para4: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        display: "flex",
        alignItems: "center",
        color: "rgba(255, 252, 252, 0.6)",
    },
    removePadding: {
        [theme.breakpoints.down('sm')]: {
            padding: "0px",
        },
    }
}));

function ContactUSDetail({dataValue}) {
   

    const classes = useStyles();
return (
    <><Typography className={classes.heading3}><img src={logoCrypto} alt="logo crypto"/></Typography><Typography style={{ marginTop: "20px" }} className={`${classes.para3} ${classes.blueColor}`}>
        </Typography><Typography className={`${classes.para3} ${classes.blueColor}`}>{dataValue?.data?.[0]?.address} </Typography><hr style={{ marginBottom: "30px", marginTop: "30px" }} /><Box className={classes.dFlex}>
        <MailOutlineIcon style={{ color: "rgba(255, 252, 252, 0.6)", marginRight: "10px" }} />
        <Typography className={classes.para4}>{dataValue?.data?.[0]?.emailAddress}</Typography>
    </Box><Typography style={{ marginLeft: "36px", marginTop: "10px" }} className={classes.para2}>
    {dataValue?.data?.[0]?.shortDetails}</Typography><hr style={{ marginBottom: "30px", marginTop: "30px" }} /><Box className={classes.dFlex}>
            <LocalPhoneOutlinedIcon style={{ color: "rgba(255, 252, 252, 0.6)", marginRight: "10px" }} />
            <Typography className={classes.para4}>Call Us at  {dataValue?.data?.[0]?.contactNumber}  </Typography>
        </Box><Typography style={{ marginLeft: "36px", marginTop: "10px" }} className={classes.para2}>Our friendly and professional Customer Service team offers assistance in several languages.</Typography></>
)
}




export default ContactUSDetail;
