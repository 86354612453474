import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { LiveLotteries } from '../types';

export const getLottery = async (): Promise<LiveLotteries[]> => {
  const response = await axios.get(`/api/v1/user/livelotteries`, {
  });
  const data = response
  return data.data.live;
};
//Response.data.upc
type QueryFnType = typeof getLottery;

type UseLotteriesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLotteries = ({  config }: UseLotteriesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getLottery(),
    ...config,
  });
};