import { createTheme } from "@material-ui/core/styles";
import { lineHeight } from "@mui/system";
import '../../index.css';


export const theme = createTheme({
  palette: {
    primary: {
      main: "#3C37FF"
    },
    secondary: {
      main: "#3C37FF"
    },
    sub:{
        main:"#fffff"
    }
},


typography: {
      fontFamily: "Ubuntu",
      fontSize: 15,
      h5: {
        // incase h1 needs to have a separate fontFamily
        fontFamily: 'Ubuntu',
        fontSize: '20px',
        color:"#fff",
        '@media (max-width:600px)': {
          fontSize: '15px !important',
        },
        '@media (max-width:1024px)': {
        fontSize: '12px !important'},
      },
      subtitle1: {
        // incase h1 needs to have a separate fontFamily
        fontFamily: 'Ubuntu',
        fontSize: '26px',
        color:"#fff",
        '@media (max-width:600px)': {
          fontSize: '22px',
        },
        '@media (max-width:1024px)': {
        fontSize: '12px !important'},
      },
      subtitle2:{
        fontFamily: 'Ubuntu',
        fontSize: '18px',
        color:"#fff",
        '@media (max-width:600px)': {
          fontSize: '14px',
        },
        '@media (max-width:1024px)': {
          fontSize: '15px !important'},
      },
      h1: {
        // incase h1 needs to have a separate fontFamily
        fontFamily: 'Ubuntu',
        fontSize: '30px',
        color:"#fff",
        '@media (max-width:1024px)': {
          fontSize: '18px !important',
        },
      },
      h6:{
        fontFamily: 'Ubuntu',
        fontSize: '16px',
        fontWeight:"normal",
        '@media (max-width:600px)': {
          fontSize: '8px !important',
        },
        '@media (max-width:1024px)': {
        fontSize: '12px !important'},
        color:"#fff",
      },
      h2:{
        fontFamily: 'Ubuntu',
        fontSize: '35px',
        '@media (max-width:600px)': {
          fontSize: '18px',
        },
        '@media (max-width:1024px)': {
          fontSize: '14px',
        },
        color:"#fff"
      },
      h4:{
        fontFamily: 'Ubuntu',
        fontSize: '25px',
        '@media (max-width:600px)': {
          fontSize: '18px',
        },
        '@media (max-width:1024px)': {
          fontSize: '14px',
        },
        color:"#fff"
      },
      h3:{
        fontFamily: 'Ubuntu',
        fontSize: '14px',
        '@media (max-width:600px)': {
          fontSize: '12px',
        },
        '@media (max-width:1024px)': {
          fontSize: '8px',
        },
        color:"#fff"
      },
      body1:{
        fontFamily: 'Ubuntu',
        fontSize: '22px',
        fontWeight:"700px",
        '@media (max-width:600px)': {
          fontSize: '12px',
        },
        '@media (max-width:1027px)': {
          fontSize: '12px',
        },
        color:"#fff"
      },
      body2:{
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "32px",
        '@media (max-width:600px)': {
          fontSize: '12px',
        },
        '@media (max-width:1027x)': {
          fontSize: '12px',
        },
        color:"#fff"
      },
      caption:{
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px"
      }
    },
    components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: "14.5px 88px",
          fontSize: "18px",
          textTransform: 'none',
          '@media (max-width:600px)': {
            fontSize: '14px',
            padding: "8px 65px",
          },
          '@media (max-width:1024px)': {
            fontSize: '14px',
            padding: "8px 65px",
          },
          fontWeight: "500",
          background: "#3C37FF",
          borderRadius:"10px",
          color: "#fff"
        }
      },   
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            background: "#050518",
            border: "1px solid rgba(38, 50, 56, 0.19)",
            boxShadow: "7px 14px 19px rgba(0, 0, 0, 0.05)",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiMenuPaper: {
      '& .MuiMenuItem-root': {
      styleOverrides: {
        root:{
      
        }
      }
    }
    },
    MuiInputBaseFormControl: {
        styleOverrides: {
                root:{
                    borderRadius:"10px",
                },
            }
    },
    MuiOutlinedInputNotchedOutline: {
      styleOverrides: {
        root:{
          border: "1px solid rgba(255, 254, 236, 0.1)",
        }
      }
    },
            MuiInputBase : {
                styleOverrides: {
                root:{
                borderRadius: "10px !important",
                
                fontSize: 14,
                width: 'calc(100% - 18px)',
                color:"#fff",
                //marginTop:"18px"
                },
    }
            },
    MuiFormLabel:{
        styleOverrides: {
        root: {
              left:"-13px !important",
              color: "rgba(255, 254, 236, 0.4) !important",
            },
        focused: {
                color:"red !important",
                borderRadius:"10px",
            },
            "&$focused": {
              color:"red",
              fontWeight: "bold"
            }
        }
    },
    MuiFocused :{
      styleOverrides: {
        root: {
      color: "yellow", /* changes the text color when focused */
      outline: "none" /* removes the default focus outline */
        }
      }
    },
    MuiBackdrop : {
        opacity:"1.8 !important"
    },
    MuiCheckBox : {
      styleOverrides: {
        root:{
          color:"yellow",
          '&.Mui-checked': {
            color: "pink",
          },
        }
      }
    }
    }
    
});
