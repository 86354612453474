import React, { useEffect } from 'react';
import { Box, Paper, Typography, Grid, Divider, Stack, Button, Avatar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import dp from "../../../assets/dp.png";
import editIcon from "../../../assets/editIcon.png";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CustomModel } from "@/components/Elements/custom-modal";
import { useAuth } from '@/lib/auth';
import { useProfile } from '../api/userDetails';
// Latest version - v3.0.0 with Tree Shaking to reduce bundle size
// Import Interfaces`

import closeModal from "@/assets/closeModal.svg";
import { postEmailVerify } from '../api/emailVerification';
import OtpInput from "react-otp-input";
// import Country, State, City  from "country-state-city";
import Swal from 'sweetalert2';
import { changePasswordData, postPasswordChange } from '../api/changePassword';
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { Country, State, City } from "country-state-city";
import { any, z } from 'zod';
import { postProfile, useProfileList } from '../api/profileSubmit';
import storage from '@/utils/storage';

import { useNavigate } from "react-router-dom";

const formSchema = z
  .object({
    firstName: z.string().min(1, "First Name is required"),
    lastName: z.string().min(1, "Last Name is required"),
    nickName: z.string().min(1, "Nick Name is required"),
    email: z.string().min(1, "email is required"),
    country: z.string().min(1, "country is required"),
    state: z.string().min(1, "state is required"),
    city: z.string().min(1, "city is required"),
    address: z.string().min(1, "address is required"),
    ZipCode: z.string().min(4, "zipcode is required"),
    image: z.string().min(1, "image is required"),
  })

type profileValue = {
  firstName: string,
  lastName: string,
  nickName: string,
  email: string,
  country: string,
  city: string,
  state: string,
  address: string,
  ZipCode: string,
  image: string,
};

const initialValues: profileValue = {
  firstName: "",
  lastName: "",
  nickName: "",
  email: "",
  country: "",
  state: "",
  city: "",
  address: "",
  ZipCode: "",
  image: "",
};
type FormSchemaType = z.infer<typeof formSchema>;

const useStyles = makeStyles((theme) => ({
  flexWrap: {
    flexWrap: "wrap",
  },
  dFlex: {
    display: "flex",
  },
  justifyEnd: {
    justifyContent: "end"
  },
  justifyCenter: {
    justifyContent: "center"
  },
  justifyBetween: {
    justifyContent: "space-between"
  },
  container: {
    margin: "20px 82px",
    [theme.breakpoints.down('sm')]: {
      margin: "0px 0px",
    },
  },
  forMedia: {
    [theme.breakpoints.down('md')]: {
      display: "block",
      margin: "auto",
      maxWidth: "282px"
    },
  },
  heading1: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.38px",
    color: "#FFFFFF"
  },
  para1: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.38px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    opacity: 0.5,
    marginTop: "15px",
    marginBottom: "5px"
  },
  blueColor: {
    color: "#3C37FF !Important",
  },
  heading2: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "25px",
    lineHeight: "140%",
    color: "#FFFFFF"
  },
  para2: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    fontFeatureSettings:
      "'cv05' on, 'cv10' on, 'cv02' on, 'cv03' on, 'cv04' on, 'ss03' on, 'ss04' on, 'ss01' on, 'kern' off, 'calt' off",
    color: "#FFFFFF"
  },
  darkBlue: {
    background: "#050518",
    padding: "60px",
    borderRadius: "10px"
  },
  width50: {
    width: "50%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  width60: {
    width: "60%"
  },
  heading3: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase"
  },
  para3: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    color: "rgba(255, 255, 255, 0.6)",
    opacity: 0.9,
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "2px"
  },
  para4: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 252, 252, 0.6)",
  },
  myProfile: {
    background: "rgba(5, 5, 24, 0.8)",
    mixBlendMode: "normal",
    borderRadius: "10px",
    // width: "50%",
    padding: "30px"
  },
  profileEditButton: {
    background: "transparent",
    border: "none",
    position: "relative",
    left: "-35px"
  },
  blueButton: {
    padding: "17px 16px",
    background: "#3C37FF",
    borderRadius: "10px",
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    border: "none",
    marginTop: "37px",
    width: "132px",
    cursor: "pointer"
  },
  ChangePassword: {
    background: "rgba(5, 5, 24, 0.8)",
    mixBlendMode: "normal",
    borderRadius: "10px",
    width: "30%",
    padding: "30px",
    marginLeft: "30px",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      marginTop: "30px"
    },
  },
  cancleButton: {
    padding: "17px 16px",
    borderRadius: "10px",
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    marginTop: "37px",
    width: "110px",
    background: "transparent",
    border: "1px solid white",
    marginLeft: "10px",
    cursor: "pointer"
  }
}));



function MyAccount() {
  const classes = useStyles();
  const [data, setData] = React.useState([])
  const { user } = useAuth();
  const [age, setAge] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [mail, setMail] = React.useState("");
  const [confirmMail, setConfirmMail] = React.useState("");
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  let {
    register,
    formState: { isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });

  const [formValues, setFormValues] = React.useState<profileValue>(initialValues);

  const [selectedCountry, setSelectedCountry] = React.useState<{ isoCode: string; name: string } | null>(null);
  const [selectedState, setSelectedState] = React.useState<{ isoCode: string; name: string } | null>(null);
  const [selectedCity, setSelectedCity] = React.useState<{ isoCode: string; name: string } | null>(null);
  // const [selectedCountry, setSelectedCountry] = React.useState(null);
  // const [selectedState, setSelectedState] = React.useState(null);
  // const [selectedCity, setSelectedCity] = React.useState(null);
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  // Form validation error states
  const [errors, setErrors] = React.useState({});
  const [previewImage, setPreviewImage] = React.useState<string>("");
  const [errorFile, setErrorFile] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("")
  const [selectedImage, setSelectedImage] = React.useState<string | null>(null); // Declare the type as 'string | null'
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [avatar, setAvatar] = React.useState(true);
  const [imageurl, setImageurl] = React.useState('');
  const [file, setFile] = React.useState(null);
  const [imageName, setImageName] = React.useState("");
  const [newImage, setNewImage] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string | null | ArrayBuffer | any>(null);
  const { logout } = useAuth();




  const handleImageChange = async (e) => {
    e.preventDefault();
    setErrorFile(false);
    setPreviewImage("");
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file) {
      console.log('image is required');
      setErrorFile(true);
      setErrorMsg("image is required")
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      console.log('select valid image.');
      setErrorFile(true);
      setErrorMsg("Lottery image must be in jpg, jpeg or png format");
      return false;
    }
    if (file.size > 262144000) {
      console.log('select valid image.');
      setErrorMsg("Lottery image must be 250 MB");
      setErrorFile(true);
      return false;
    }
    setSelectedImage(e.target.files[0]);
    setFile(file);
    reader.onloadend = () => {
      setAvatar(true);
      setImagePreviewUrl(reader.result);
      setImageurl(e.target.files[0])
      setPreviewImage(URL.createObjectURL(e.target.files?.[0]));
      setNewImage(e.target.files[0]);
      setNewImage(e.target.files[0]);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setFormValues({
      ...formValues,
      image: file || null, // Update image value, or set to null if no image is selected
    });
    console.log(file, imagePreviewUrl)
  };

  const handleChange = event => {
    console.log(formValues, "zipcodeFrom")
    // Handle changes for the text fields
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

    // Clear the validation error for the changed field
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const handleSimple = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "country") {
      const country = Country.getCountryByCode(e.target.value);
      if (country) {
        setSelectedCountry(country || null);
      } else {
        setSelectedCountry(null); // Set to null if the country is not found or undefined
      }
    } else if (e.target.name === "state") {
      const state = State.getStateByCode(e.target.value);
      if (state) {
        setSelectedState(state || null);
      } else {
        setSelectedState(null); // Set to null if the state is not found or undefined
      }
    } else if (e.target.name === "city") {
      setSelectedCity(e.target.value || null);
    }
  };


  const handleEmail = (event) => {
    setOpenModal(true);
  };


  const handleVerifyEmail = (e) => {
    e.preventDefault();
    let data = { emailId: mail, confirmEmailId: confirmMail };
    postEmailVerify(data)
  }


  const handleClose = () => {
    setOpenModal(false);
  }

  const dataValue = useProfile({});
  React.useEffect(() => {
    if (dataValue.isSuccess) {
      const profileData = dataValue.data; // Adjust this to match the structure of your API response
      setFormValues({
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        nickName: profileData.nickName,
        email: profileData.email,
        country: profileData.country,
        state: profileData.state,
        city: profileData.city,
        address: profileData.address,
        ZipCode: profileData.ZipCode == null ? "" : profileData.ZipCode,
        image: profileData.image,
      });
      const country = Country.getCountryByCode(profileData.country);
      setImageurl(`${process.env.API_URL_PREFIX}/api/v1/user/uploads/` + profileData?.image)
      setImagePreviewUrl(`${process.env.API_URL_PREFIX}/api/v1/user/uploads/` + profileData?.image);
      console.log(`${process.env.API_URL_PREFIX}/api/v1/user/uploads/` + profileData?.image);
      setSelectedCountry(country || null);
      const state = State.getStateByCode(profileData.state);
      setSelectedState(state || null);
      setSelectedCity(profileData.city || null);
    }
  }, [dataValue.isSuccess, dataValue.data]); // Only run the effect when isSuccess or data changes

  console.log("file",dataValue)
  const [showPassword, setShowPassword] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleClickShowPassword = async () => setShowPassword((show) => !show);

  const handleSubmit = async () => {
    // Validation logic here
    if (!oldPassword || !newPassword || !confirmPassword) {
      Swal.fire({
        icon: 'error',
        background: "black",
        color: "#ffff",
        title: 'Validation Error',
        text: 'Please fill all fields',
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        background: "black",
        color: "#ffff",
        title: 'Validation Error',
        text: 'New password and Confirm password do not match',
      });
      return;
    }
    let data = {
      "oldPassword": oldPassword,
      "newPassword": newPassword,
      "confirmPassword": confirmPassword,
    }

    try {
      setIsLoading(true);
      // Make the API request using lotteryDataValue function
      await postPasswordChange({ data })
        .then((res) => {
          Swal.fire({
            title: 'Logout?',
            text: 'Password changed successfully!! Do want to logout?',
            icon: 'question',
            showCancelButton: true,
            background: "black",
            color: "#ffff",
            customClass: {
              actions: 'swal-actions-class',
              popup: 'swal-popup-class',
            },
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            focusConfirm: true,
            allowOutsideClick: false,
            confirmButtonText: 'Yes, logout',
            cancelButtonText: 'Cancel',
          }).then((res) => {
            if (res.isConfirmed) {
              storage.clearToken();
              setTimeout(() => {
                navigate("/auth/login")
              }, 1000)
            }
            else
              navigate("/")

          })
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: "Password Not changed",
      });
    }

  };


  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // const validateForm = () => {
  //   // Implement your form validation logic here
  //   // You can check for required fields, valid formats, etc.
  //   const errors = {};
  //   // Example validation for required fields:
  //   if (!formValues.firstName.trim()) {
  //     errors.firstName = 'First Name is required';
  //   }
  //   // Add more validation rules for other fields as needed

  //   return errors;
  // };


  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in formValues) {
      formData.append(key, formValues[key]);
    }
    try {
      const response = await postProfile(formData);

      if (response) {
        Swal.fire({
          title: 'Success!',
          background: "black",
          color: "#ffff",
          text: 'Profile updated successfully',
          icon: 'success',
        }).then(()=>{
          navigate("/app/wallet/MyLottery");
          window.location.reload()
        });
      } else {
        Swal.fire({
          title: 'Error!',
          background: "black",
          color: "#ffff",
          text: 'Failed to update profile',
          icon: 'error',
        }).then(()=>{
          navigate("/app/wallet/MyLottery");
          window.location.reload()
        });;
      }
     
    } catch (error) {
      console.error('Error updating profile:', error);
      Swal.fire({
        title: 'Error!',
        background: "black",
        color: "#ffff",
        text: 'An error occurred',
        icon: 'error',
      });
    }
  }
  return (
    <>
      <Grid container className={`${classes.dFlex}`} sx={{ mt: "120px" , mb:"40px",  justifyContent:"space-around" , px:"20px" }}>
        <Grid sm={12} md={7} className={classes.myProfile}>
          <Typography className={classes.heading1}>My Profile</Typography>
          <Box>
            <Box className={`${classes.dFlex} ${classes.justifyBetween}`}>
              <Typography className={classes.para1}>Personal Details</Typography>
              <Box className={classes.dFlex}>
                <ManageAccountsOutlinedIcon className={classes.blueColor} style={{ marginTop: "3px", marginRight: "5px" }} />
              </Box>
            </Box>
            <hr style={{ marginBottom: "40px" }} />
            <form onSubmit={handleSubmitForm}>
              <Grid container spacing={1}>
                <Grid xs={12} md={4}>
                  <Avatar
                    alt="Preview"
                    src={imagePreviewUrl}
                    sx={{ width: 100, height: 100, borderRadius: "50%", border: "5px solid blue", marginLeft: "15px" }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }} // Hide the default input style
                    id="image-input"
                  />
                  <label htmlFor="image-input">
                    <Button variant="outlined" component="span" sx={{ mt: "20px" }}>
                      Choose Image
                    </Button>
                  </label>
                  <button className={classes.profileEditButton}></button>
                </Grid>
                <Grid xs={12} md={8}>
                  <Grid container spacing={1} className={classes.dFlex}>
                    <Grid xs={12} sm={6}>
                      <Typography className={classes.para3}>First Name</Typography>

                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        {...register('firstName')}
                        value={formValues?.firstName}
                        onChange={handleChange}
                        placeholder='Enter your name'
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Typography className={classes.para3}>Last Name</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        name="lastName"
                        onChange={handleChange}
                        placeholder='Enter your last name'
                        value={formValues.lastName}
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.dFlex}>
                    <Grid item xs={12} sm={6} sx={{ pl: "0 !important" }}>
                      <Typography className={classes.para3}>Nick Name</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        {...register('nickName')}
                        onChange={handleChange}
                        placeholder='Enter your nick name'
                        value={formValues.nickName}
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className={classes.dFlex}>
                    <Grid item xs={12} sm={8} sx={{ pl: "0 !important" }}>

                      <Typography className={classes.para3}>Email</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        {...register('email')}
                        placeholder='Enter your Email'
                        onChange={handleChange}
                        value={formValues.email}
                        multiline
                        maxRows={4}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} >
                      <button className={classes.blueButton} onClick={handleEmail}>Change Email</button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.dFlex}>
                    <Grid item xs={12} sm={4} sx={{ pt: "8px", pl: "0 !important" }}>
                      <Typography className={classes.para3}>Country</Typography>
                      <FormControl style={{ width: "100%" }}>
                        <Select id="country" name="country" value={selectedCountry !== null ? selectedCountry?.isoCode : 0}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                bgcolor: "black",
                                "& .MuiMenuItem-root": {
                                  padding: 1,
                                  fontFamily: "Ubuntu",
                                  "font-style": "normal",
                                  "font-weight": 400,
                                  fontSize: "14px",
                                },
                              },
                            },
                          }}
                          onChange={handleSimple}>
                          <MenuItem disabled value={0}>
                            Select Country
                          </MenuItem>
                          {Country.getAllCountries().map((country) => (
                            <MenuItem key={country.isoCode} value={country.isoCode}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} sx={{ pt: "8px" }}>
                      <Typography className={classes.para3}>State</Typography>

                      <FormControl fullWidth>
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-multiline-flexible"
                          {...register('state')}
                          value={formValues?.state}
                          onChange={handleChange}
                          placeholder='Enter State Name'
                          maxRows={4}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={12} sm={4} sx={{ p: 0, pt: "8px" }}>
                      <Typography className={classes.para3}>City</Typography>
                      <FormControl fullWidth>
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-multiline-flexible"
                          {...register('city')}
                          value={formValues?.city}
                          onChange={handleChange}
                          placeholder='Enter City Name'
                          maxRows={4}
                        />
                      </FormControl>
                    </Grid></Grid>

                  <Grid container spacing={1} className={classes.dFlex} >
                    <Grid xs={12} sm={6} sx={{ pt: "10px" }}>
                      <Typography className={classes.para3}>Address</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        placeholder='Enter your Address'
                        name="address"
                        onChange={handleChange}
                        value={formValues.address}
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ pt: "10px" }}>
                      <Typography className={classes.para3}>Zip Code</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        placeholder='Enter your Zip Code'
                        onChange={handleChange}
                        name="ZipCode"
                        value={formValues.ZipCode}
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={classes.dFlex} sx={{ justifyContent: "center", paddingRight: "20px" }}>
                    <Grid item sx={{ justifyContent: "center", paddingRight: "20px" }} >
                      <button className={classes.blueButton} type="submit">Submit</button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>


            </form>
          </Box>
        </Grid>
        <Grid xs={12} md={4} className={classes.ChangePassword}>
          <Typography className={classes.heading1}>Change Password</Typography>
          <Box className={`${classes.dFlex} ${classes.justifyBetween}`}>
            <Typography className={classes.para1}>Enter details to proceed</Typography>
          </Box>
          <hr style={{ marginBottom: "40px" }} />
          <Typography className={classes.para3}>Old Password</Typography>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder='Enter Old Password'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: "white" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography className={classes.para3}>New Password</Typography>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder='Enter New Password'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: "white" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography className={classes.para3}>Confirm Password</Typography>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            placeholder='Confirm Password'
            onChange={(e) => setConfirmPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: "white" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Box className={classes.dFlex}>
            <button className={classes.blueButton} onClick={handleSubmit}>Save Changes</button>
            <button className={classes.cancleButton}>Cancel</button>
          </Box>
        </Grid>
        <CustomModel
          open={openModal}
          onClose={() => setOpenModal(false)}
          childComponent={
            <>
              <Stack direction="row" height="100%" sx={{ p: 0 }}>
                <Box
                  sx={{ width: "100%", overflow: "auto", mt: "50px" }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >

                  <Grid container>
                    <Grid xs={12} sm={6}>
                      <Typography className={classes.para3}>Email Id</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        placeholder='Enter your Email Id'
                        onChange={(e) => setMail(e.target.value)}
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Typography className={classes.para3}>Confirm Mail Id</Typography>
                      <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-flexible"
                        placeholder='Enter your Confirm Mail Id'
                        onChange={(e) => setConfirmMail(e.target.value)}
                        multiline
                        maxRows={4}
                      />
                    </Grid>
                    <Button variant="contained" onClick={handleVerifyEmail} sx={{ mt: "20px" }}>
                      Verify
                    </Button>
                    <Button variant="contained" onClick={handleClose} sx={{ mt: "20px" }}>
                      close
                    </Button>
                  </Grid>
                </Box>
                <Box>
                  <IconButton sx={{ padding: "25px" }} onClick={handleClose}>
                    <img src={closeModal} />
                  </IconButton>
                </Box>
              </Stack>
            </>
          }
        />
        <CustomModel
          open={openModal}
          onClose={() => setOpenModal(false)}
          childComponent={
            <>
              <Stack direction="row" height="100%" sx={{ p: 0 }}>
                <Box
                  sx={{ width: "100%", overflow: "auto", mt: "50px" }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >

                  <Typography
                    component="h4"
                    variant="h4"
                    sx={{ mt: "20px", textAlign: "center" }}
                  >
                    Check your Email
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{ textAlign: "center", mt: "15px", mb: "20px" }}
                  >
                    We sent a verification code to <br /> <span style={{ color: "#3C37FF" }}>{confirmMail}</span>
                  </Typography>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span style={{ marginRight: "10px" }}></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      border: "1px Solid rgba(255, 255, 255, 0.31)",
                      borderRadius: "9px",
                      color: "#FFFF",
                      background: "transparent",
                      height: "59px",
                      width: "59px",
                      fontSize: "20px"
                    }}
                  />
                  <Grid container>
                    <Grid item lg={12}>
                      <Typography
                        component="h3"
                        variant="h3"
                        sx={{ textAlign: "center", mt: "40px" }}
                      >
                        Didn't receive the email?
                        <Button>
                          {/* <span  style={{ "textTransform":"capitalize" }} onClick={handleResend}>Resend</span>  */}
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sx={{
                        mt: 3,
                        mb: "45px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button variant="contained" >
                        Verify
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <IconButton sx={{ padding: "25px" }} onClick={handleClose}>
                    <img src={closeModal} />
                  </IconButton>
                </Box>
              </Stack>
            </>
          }
        />
      </Grid>
    </>
  )
}

export default MyAccount;