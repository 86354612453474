import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { payment } from '../types';

export const getPayment = async (): Promise<payment[]> => {
  const response = await axios.get(`/api/v1/user/fetchPaymentCurrency`, {
  });
  const data = response
  return data.data;
};
//Response.data.upc
type QueryFnType = typeof getPayment;

type UsePaymentOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const usePayment = ({  config }: UsePaymentOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getPayment(),
    ...config,
  });
};