import * as React from "react";
import Header from "../Elements/header";
import Footer from "../Elements/footer";
import HomeScreenImg from "@/assets/homeScreenBackground.svg";
import { Box } from "@mui/material";
import HeaderMain from "../Elements/headerMain";

type MainLayoutProps = {
  children: React.ReactNode;
};

export const HomeLayout = ({ children }: MainLayoutProps) => {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <HeaderMain />
      {children}
      <Footer />
    </Box>
  );
};
