import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { lotteryList } from '../types';

export const getLotteryList = async (): Promise<lotteryList[]> => {
  const response = await axios.get(`/api/v1/user/myLottery`, {
  });
  const data = response.data;

  return data;
};

type QueryFnType = typeof getLotteryList;

type UseLotteryListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLotteryList = ({  config }: UseLotteryListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getLotteryList(),
    ...config,
  });
};