import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Wheel } from "../Wheel";
import { postLotteryDraw } from "../api/lotteryDrawId";
import { useLotteryTicket } from "../api/lotteryTicketList";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Particles from "react-tsparticles";
import { loadFireworksPreset } from "tsparticles-preset-fireworks";
import yellowt from "@/assets/yellow-t.png";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import Countdown from 'react-countdown';
import { loadFull } from "tsparticles";
import CurrencyFormat from 'react-currency-format';
import Confetti from "react-confetti";
import {
  Divider,
  Modal,
  Grid,
  Paper,
  Table,
  TableBody,
  Button,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import CountUp from "react-countup";
import arrow from "@/assets/arrow.png";
import ad from "@/assets/add.svg";
import useWindowSize from "react-use/lib/useWindowSize";
//import logo from '@/assets/logo.svg';
// import { Button } from '@/components/Elements';
// import { Head } from '@/components/Head';
import { useAuth } from "../../../lib/auth";
import { Box, createStyles } from "@mui/material";
import sound from "../../../assets/carnival (1).mp3";
import number_sound from "../../../assets/number_appearing.mp3";
import whitet from "@/assets/white-t.png";
import axios from "axios";
import "./wheel.css";
import { TypeOf, any } from "zod";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import storage from "@/utils/storage";
import { lotteryData } from "@/features/buyNow/types";
import { BorderRight, ImportantDevices, ScatterPlot } from "@material-ui/icons";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { useParams } from "react-router-dom";
import lotteryFrame from "@/assets/spinnerFrame.png";
import lotteryName from "@/assets/mervIcon.svg";
import rpm from "@/assets/rpmSpinner.svg";
import speed from "@/assets/speedSpinner.svg";
import WinningHistoryWheel from "./winningHistory";
import WinModal from "./winModal";
import LostModal from "./lostModal";
import PrizeModal from "./prizeModal";
import LotteryPoolModal from "./lotteryPoolModal";

const dataValue = {
  heading: "",
  subHeading: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  leftPosition: {
    [theme.breakpoints.down("sm")]: {
      left: "16% ! important",
    },
  },
  boxShadow: {
    "-webkit-box-shadow": "1px 4px 9px 1px rgba(59,151,187,1)",
    "-moz-box-shadow": "1px 4px 9px 1px rgba(59,151,187,1)",
    "box-shadow": "1px 4px 9px 1px rgba(59,151,187,1)",
    borderRight: "4px solid #3b97bb",
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
  first: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 467,
  },
  second: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 150,
  },
  parentPaper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 1600,
  },
  availableTickets: {
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    height: 110,
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
    p: { xs: "0px !important", md: "25px" },
  },
  table: {
    maxWidth: "280",
  },
  tableHeader: {
    color: "#fff",
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  logo: {
    width: 50,
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      minHeight: "200px",
      minWidth: "200px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
  },
  ticketContainer: {
    width: "119px",
    height: "60px",
    background: "#FFFF",
    borderRadius: "10px",

    position: "relative",
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    padding: "12.86px",
  },
  detailLabel: {
    height: "11px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "11px",
    color: "rgba(27, 28, 45, 0.6)",
    margin: "0",
  },
  detailNumber: {
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "0",
  },
  detailTime: {
    width: "82px",
    height: "16px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#1B1C2D",
    margin: "0",
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    //height: "100%",
    maxHeight: "300px",
    // height: "270px",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(180deg, #3b97bb 0%, #aed1db 99%)",
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      borderRadius: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "200px",
    },
  },
  jackpotContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    height: "38px",
    background: "rgba(60, 55, 255, 0.15)",
    marginBottom: "14px",
  },
  jackpotText: {
    width: "99px",
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.2em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "auto",
  },
  arrowImage: {
    position: "absolute",
    width: "30px",
    height: "30px",
    left: "74%",
    transform: "translateX(-50%)",
  },
  polyup: {
    top: "-15px",
    left: "86%",
  },
  polydown: {
    bottom: "-15px",
    transform: "rotate(180deg)",
  },
}));

//import logo from '@/assets/logo.svg';

const data1 = [
  { option: "0", style: { textColor: "#FFFF" } },
  { option: "1", style: { textColor: "#FFFF" } },
  { option: "2", style: { textColor: "#FFFF" } },
  { option: "3", style: { textColor: "#FFFF" } },
  { option: "4", style: { textColor: "#FFFF" } },
  { option: "0", style: { textColor: "#FFFF" } },
  { option: "1", style: { textColor: "#FFFF" } },
  { option: "2", style: { textColor: "#FFFF" } },
  { option: "3", style: { textColor: "#FFFF" } },
  { option: "4", style: { textColor: "#FFFF" } },
];

const data2 = [
  { option: "0", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "1", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "2", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "3", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "4", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "5", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "6", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "7", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "8", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "9", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
];
const data3 = [
  {
    option: "0",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "1",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "2",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "3",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "4",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "5",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "6",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "7",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "8",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "9",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
];

const data4 = [
  { option: "0", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "1", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "2", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "3", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "4", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "5", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "6", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "7", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "8", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "9", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
];
const data5 = [
  {
    option: "0",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "1",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "2",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "3",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "4",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "5",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "6",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "7",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "8",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
  {
    option: "9",
    style: { textColor: "rgba(255,255,255,1.00)", fontWeight: "bold" },
  },
];
const data6 = [
  { option: "0", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "1", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "2", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "3", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "4", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "5", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "6", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "7", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "8", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
  { option: "9", style: { textColor: "rgb(6, 6, 34, 1)", fontWeight: "bold" } },
];

const backgroundColors = [
  "#E40303",
  "#750787",
  "#1C6CF2",
  "#008026",
  "#FF8C00",
];
const textColors = ["#FFFFF"];
const outerBorderColor = "#eeeee";
const outerBorderWidth = 2;
const outerBorderWidth4 = 6;

const innerBorderColor = "#30261a";
const innerBorderWidth = 2;
const innerRadius = 0;
const radiusLineColor = "#E40303";
const radiusLineWidth = 0;
const fontFamily = "Nunito";
const fontSize1 = 15;
const fontSize2 = 26;
const fontSize3 = 30;
const fontSize4 = 38;
const fontSize5 = 48;
const fontSize6 = 60;
const textDistance1 = 92;
const textDistance2 = 92;
const textDistance3 = 93;
const textDistance4 = 92;
const textDistance5 = 94;
const textDistance6 = 90;

//  distance = π*diameter * number of revolutions

//  number of revolutions = distance/(π*diameter)

// const spinDuration1 = 2;
// const spinDuration2 = ;
// const spinDuration3 = 0.5;
// const spinDuration4 = 0.5;
// const spinDuration5 = 0.5;
// const spinDuration6 = 0.5;

const fontWeight = "bold";

export const Landing = (_DrawWheelPropsData: any) => {
  var result = new Date();
  var targetDate = result.setDate(result.getDate() + 2);
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getDate()
  );
  const { user } = useAuth();
  const { id } = useParams();
  const [isWinOpen, setIsWinopen] = React.useState(false);
  const [isLostOpen, setIsLostopen] = React.useState(false);
  const [isPrizeOpen, setIsPrizeOpen] = React.useState(false);
  let dataValue = {
    lotteryId: "649d4943c0a0d39915560799",
  };
  const dataLottery = useLotteryTicket({ LotteryId: id || "" });
  const navigate = useNavigate();
  const [WinnerArray, setWinnerArray] = useState<any[]>([]);
  const [startingOptionIndex, setstartingOptionIndex] = useState(0);
  const [playSound, setPlaySound] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isVisiblePrize1, setIsVisiblePrize1] = useState(false);
  const [isVisiblePrize2, setIsVisiblePrize2] = useState(false);
  const [isVisiblePrize3, setIsVisiblePrize3] = useState(false);
  const [isVisiblePrize4, setIsVisiblePrize4] = useState(false);
  const [isVisiblePrize5, setIsVisiblePrize5] = useState(false);
  const [isVisiblePrize6, setIsVisiblePrize6] = useState(false);
  const [avgSpeed, setAvgSpeed] = useState(0);
  const [lotterySpin, setLotterySpin] = useState([]);
  const [decelaration, setDecelaration] = useState(0);
  const [isSpinDone, setIsSpinDone] = useState(false);
  const [message, setMessage] = useState(
    "Sorry, you did not win. Fingers crossed for the next Spin."
  );
  const [lotteryNameData, setLotteryName] = useState("");
  const [lotteryImage, setLotteryImage] = useState("");
  const [spinDuration1, setSpinDuration1] = useState(0);
  const [spinDuration2, setSpinDuration2] = useState(0);
  const [spinDuration3, setSpinDuration3] = useState(0);
  const [spinDuration4, setSpinDuration4] = useState(0);
  const [spinDuration5, setSpinDuration5] = useState(0);
  const [spinDuration6, setSpinDuration6] = useState(0);

  const [winnerWithTicket, setWinnerWithTicket] = useState(false);
  const [lotteryPool, setLotteryPool] = useState(false);
  const [noOfTicketsSold, setNoOfTicketsSold] = useState(0);
  const [percentageOfTicketSold, setPercentageOfTicketSold] = useState(0);
  const [totalPrizeOfTicket, setTotalPrizeOfTicket] = useState(0);

  useEffect(() => {
    setSpinDuration2((prev) => spinDuration1 - 0.3);
  }, [spinDuration1, avgSpeed]);

  useEffect(() => {
    setSpinDuration3((prev) => spinDuration2 - 0.3);
  }, [spinDuration2, avgSpeed]);

  useEffect(() => {
    setSpinDuration4((prev) => spinDuration3 - 0.3);
  }, [spinDuration3, avgSpeed]);

  useEffect(() => {
    setSpinDuration5((prev) => spinDuration4 - 0.3);
  }, [spinDuration4, avgSpeed]);

  useEffect(() => {
    setSpinDuration6((prev) => spinDuration5 - 0.3);
  }, [spinDuration5, avgSpeed]);

  const { width, height } = useWindowSize();

  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    // this adds the preset to tsParticles, you can safely use the
    await loadFireworksPreset(engine);
    //loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    console.log(container);
  }, []);
  const audioElement = useRef<HTMLAudioElement | null>(null);
  const [audio] = useState(new Audio(number_sound));
  const [playing, setPlaying] = useState(false);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (audioElement.current) {
      if (isMuted) {
        setIsAudioPlaying(false); // Unmute and set volume to 50%
        setPlaySound(false);
        audioElement?.current?.pause();
      } else {
        audioElement?.current?.play();
        // audioElement.loop = true;
        setIsAudioPlaying(true); // Mute
        setPlaySound(true);
      }
    }
  }, [isMuted]);

  const handleWinClose = () => {
    setIsWinopen(false);
  };

  const handleLostClose = () => {
    setIsLostopen(false);
  };

  const handleLotteryPoolClose = () => {
    setLotteryPool(false);
  };

  const handlePrizeClose = () => {
    setIsPrizeOpen(false);
  };

  const getData = async () => {
    let token = storage.getToken();
    // let formData
    // useLotteryTicketList({lotteryId:"649d4943c0a0d39915560799"})

    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/drawlist/` + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setLotterySpin(res.data.drawPrize);
        setLotteryName(res.data.lotteryName);
        setLotteryImage(res.data.lotteryImage);
        //setWinnerArray(res.data.data.lotteryDrawTktNum);
      })

      .catch((err) => {
        console.log(err);
      });
    // await axios
    //   .get(
    //     `https://cryptolotteryapi.dev.vinove.com/api/v1/user/rpmAndbreakAverage/` +
    //       id,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     setAvgSpeed(res.data.avgSpeed);
    //     setDecelaration(res.data.deceleration);
    //     //setWinnerArray(res.data.data.lotteryDrawTktNum);
    //   })

    //   .catch((err) => {
    //     console.log(err);
    //   });
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/lottery/` + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res?.data?.data?.lotteryDrawTktNum.length) {
          setWinnerArray(res.data.data.lotteryDrawTktNum);
        } else {
          setWinnerArray(res.data.data.lotteryDrawTktNumStore);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getRPMData = async () => {
    let token = storage.getToken();

    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/rpmAndbreakAverage/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setAvgSpeed(res.data.avgSpeed);
        setDecelaration(res.data.deceleration);
        //setWinnerArray(res.data.data.lotteryDrawTktNum);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const classes = useStyles();

  const [valueData, setValueData] = useState(false);
  const [prizeNumber1, setPrizeNumber1] = useState(0);
  const [prizeNumber2, setPrizeNumber2] = useState(0);
  const [prizeNumber3, setPrizeNumber3] = useState(0);
  const [prizeNumber4, setPrizeNumber4] = useState(0);
  const [prizeNumber5, setPrizeNumber5] = useState(0);
  const [prizeNumber6, setPrizeNumber6] = useState(0);
  const [showModal, setShowModal] = useState(true);

  const [formData, setFormData] = useState({
    lotteryId: "",
    ticketNumber: "",
    lotteryRank: 0,
    lotteryWinningPrize: "",
  });
  const [wheelRotations, setWheelRotations] = useState(WinnerArray.length);
  const [count, setCount] = useState(0);
  const [countValue, setCountValue] = useState(1);
  const [countWin, setCountWin] = useState(2);
  const [hasCompletedRotation, setHasCompletedRotation] = useState(false);
  const [winningList, setWinningList] = useState([{ _id: "" }]);
  const [remainingTime, setRemainingTime] = useState(); // 5 minutes in seconds
  const [startSpinning, setMustSpin] = useState(false);
  const [startSpinning6, setMustSpin6] = useState(false);
  const [startSpinning5, setMustSpin5] = useState(false);
  const [startSpinning4, setMustSpin4] = useState(false);
  const [startSpinning3, setMustSpin3] = useState(false);
  const [startSpinning2, setMustSpin2] = useState(false);
  const [startSpinning1, setMustSpin1] = useState(false);

  const [startDraw, setStartDraw] = useState(false);

  useEffect(() => {
    fetchremainingTime();
    getData();
    getTicketPurchaseSummary();
  }, []);

  const fetchremainingTime = async () => {
    let token = storage.getToken();
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/endDateDrawDateTime/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          }
        }
      )
      .then((res) => {
        setRemainingTime(res?.data?.drawDateDateTime);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeDifference = res?.data?.drawDateInSec - currentTime; // Remaining time in seconds
        if (timeDifference >= 0) {
          setLotteryPool(false);
          // setRemainingTime(res?.data?.drawDateDateTime);
        } else {
          setShowModal(false);
          setLotteryPool(true);
          getRPMData();
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  // useEffect(() => {
  //   console.log("remaining time in useEffect", remainingTime);
  //   if (remainingTime < 0) {
  //     setShowModal(false);
  //     getData();
  //   }
  //   const timer = setInterval(() => {
  //     if (remainingTime >= 0) {
  //       console.log("redmaining time", remainingTime);
  //       setRemainingTime((prevTime) => prevTime - 1);
  //     } else if (remainingTime === 0) {
  //       setShowModal(false);
  //       getData();
  //     }
  //   }, 1000); // Update every second

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [remainingTime]);

  const setPrizeNumbers = async (values: any[]) => {
    return new Promise((_resolve) => {
      values.forEach((value: any, index: number) => {
        const setter = eval(`setPrizeNumber${index + 1}`);
        setter(value);
      });
    });
  };

  const setMustSpins = async (values: any[]) => {
    return new Promise((_resolve) => {
      values.forEach((_value: any, index: number) => {
        const setter = eval(`setMustSpin${index + 1}`);
        setter(true);
      });
    });
  };

  const setMustSpinsStop = (values: any[]) => {
    values.forEach((_value: any, index: number) => {
      const setter = eval(`setMustSpin${index + 1}`);
      setter(false);
    });
  };
  useEffect(() => {
    console.log("useEffect call");

    if (startDraw) {
      if (
        hasCompletedRotation &&
        isVisiblePrize1 &&
        isVisiblePrize2 &&
        isVisiblePrize3 &&
        isVisiblePrize4 &&
        isVisiblePrize5 &&
        isVisiblePrize6 &&
        WinnerArray.length - 1 !== count
      ) {
        setTimeout(() => {
          setCount((prevIndex) => prevIndex + 1);
          setCountWin((prevIndex) => prevIndex + 1);
          setIsVisiblePrize1(false);
          setIsVisiblePrize2(false);
          setIsVisiblePrize3(false);
          setIsVisiblePrize4(false);
          setIsVisiblePrize5(false);
          setIsVisiblePrize6(false);
          setHasCompletedRotation(false);
          setCountValue((prevIndex) => prevIndex + 1);
        }, 10000);
      }
      const spinNextWheel = async () => {
        if (wheelRotations >= 0 && WinnerArray.length > 0) {
          //3 //2 //1
          await handleSpinClick(WinnerArray[count]); //0 , //1, //2 (6
          //3 //-1 //-2
        }
        //requestAnimationFrame(spinNextWheel);
      };

      if (!hasCompletedRotation) {
        spinNextWheel();
      }
    }

  }, [WinnerArray, hasCompletedRotation, startDraw]);

  const handleSpinClick = async (value: any) => {
    setIsMuted(false);
    // toggleMute();
    //setPlaySound(t);
    if (!startSpinning) {
      setMustSpin1(true);
      setTimeout(() => {
        setMustSpin2(true);
      }, 1000);
      setTimeout(() => {
        setMustSpin3(true);
      }, 2000);
      setTimeout(() => {
        setMustSpin4(true);
      }, 3000);
      setTimeout(() => {
        setMustSpin5(true);
      }, 4000);
      setTimeout(() => {
        setMustSpin6(true);
      }, 5000);
      let valuedata: number[];
      valuedata = Array.from(value?.toString()).map(Number);
      setMustSpins([0, 1, 2, 3, 4, 5]);
      setPrizeNumbers(valuedata);
    }
  };
  const getRotationDegrees = (
    prizeNumber: number,
    numberOfPrizes: number,
    randomDif = true
  ): number => {
    const degreesPerPrize = 360 / numberOfPrizes; //360/9--> 40

    const initialRotation = degreesPerPrize / 2; //--> 90+20

    const randomDifference = (-1 + Math.random() * 2) * degreesPerPrize * 0.35;

    const perfectRotation =
      degreesPerPrize * (numberOfPrizes - prizeNumber) - initialRotation; //-->400-70--> 330 -360

    const prizeRotation = perfectRotation;

    return numberOfPrizes - prizeNumber > numberOfPrizes / 2
      ? -360 + prizeRotation + 1400
      : prizeRotation + 1400;
  };

  const handleWheelStopSpinning = (_index: number, _rotation: any) => {
    // Update the wheel rotation in the array
    if (_index === 1) {
      const targetElement1 = document.getElementsByClassName(
        "circle1 circle-first"
      )[0].childNodes[0].childNodes[0];
      if (targetElement1.nodeType === Node.ELEMENT_NODE) {
        const element = targetElement1 as HTMLElement;
        element.style.transform = `rotate(${getRotationDegrees(
          _rotation,
          10,
          true
        )}deg)`; //420
        setIsVisiblePrize1(true);
      }
    } else if (_index === 2) {
      const targetElement2 = document.getElementsByClassName(
        "circle2 circle-second"
      )[0]?.childNodes[0]?.childNodes[0];
      if (targetElement2.nodeType === Node.ELEMENT_NODE) {
        const element = targetElement2 as HTMLElement;
        element.style.transform = `rotate(${getRotationDegrees(
          _rotation,
          10,
          true
        )}deg)`; //350
        // element.style.transition= `rotate 1s`;
        setIsVisiblePrize2(true);
      }
    }
    if (_index === 3) {
      const targetElement3 = document.getElementsByClassName(
        "circle3 circle-third"
      )[0].childNodes[0].childNodes[0];
      if (targetElement3.nodeType === Node.ELEMENT_NODE) {
        const element = targetElement3 as HTMLElement;
        element.style.transform = `rotate(${getRotationDegrees(
          _rotation,
          10,
          true
        )}deg)`; //300
        // element.style.transition= `rotate 1s`;
        setIsVisiblePrize3(true);
      }
    }
    if (_index === 4) {
      const targetElement4 = document.getElementsByClassName(
        "circle4 circle-four"
      )[0].childNodes[0].childNodes[0];
      if (targetElement4.nodeType === Node.ELEMENT_NODE) {
        const element = targetElement4 as HTMLElement;
        element.style.transform = `rotate(${getRotationDegrees(
          _rotation,
          10,
          true
        )}deg) `;

        setIsVisiblePrize4(true);
      }
    }
    if (_index === 5) {
      const targetElement5 = document.getElementsByClassName(
        "circle5 circle-five"
      )[0].childNodes[0].childNodes[0];
      if (targetElement5.nodeType === Node.ELEMENT_NODE) {
        const element = targetElement5 as HTMLElement;
        element.style.transform = `rotate(${getRotationDegrees(
          _rotation,
          10,
          true
        )}deg)`;
        setIsVisiblePrize5(true);
      }
    }
    if (_index === 6) {
      const targetElement6 =
        document.getElementsByClassName("circle6 circle-six")[0].childNodes[0]
          .childNodes[0];
      if (targetElement6.nodeType === Node.ELEMENT_NODE) {
        const element = targetElement6 as HTMLElement;
        element.style.transform = `rotate(${getRotationDegrees(
          _rotation,
          10,
          true
        )}deg)`;
        setIsVisiblePrize6(true);
      }
    }
  };
  //Argument of type '{ data(data: any): unknown; _id: ""; lotteryId: ""; price: 0; userId: ""; ticketNumber: ""; speed: ""; deceleration: ""; transactionType: ""; detail: ""; numOfPurchaseTicket: 0; ticketNumAllocate: ""; lotteryCompletedStatus: true; ... 4 more ...; updatedAt: ""; }' is not assignable to parameter of type 'never'.ts(2345)
  useEffect(() => {
    let token = storage.getToken();
    if (
      WinnerArray.length > 0 &&
      isVisiblePrize1 &&
      isVisiblePrize2 &&
      isVisiblePrize3 &&
      isVisiblePrize4 &&
      isVisiblePrize5 &&
      isVisiblePrize6
    ) {
      setHasCompletedRotation(true);
      setPlaySound(false);
      setFormData({
        lotteryId: id?.toString() || "",
        ticketNumber: dataLottery?.data?.data[count]
          ? dataLottery?.data?.data[count]
          : "",
        lotteryRank: count,
        lotteryWinningPrize: "1000",
      });
      if (wheelRotations !== 0) setWheelRotations((prevIndex) => prevIndex - 1);
      else if (wheelRotations === 0) setWheelRotations(0);
      postLotteryDraw({
        lotteryId: id?.toString() || "",
        ticketNumber: WinnerArray[count] ? WinnerArray[count] : "",
        lotteryRank: count,
        lotteryWinningPrize: lotterySpin[count],
      });
      getWinner();
      getWinnerStatus(WinnerArray[count]);

      setMustSpinsStop([0, 1, 2, 3, 4, 5]);
      if (WinnerArray.length === count + 1) {
        setMessage(
          "Sorry, you did not win. Try your luck in the next Lottery."
        );
      }
      if (WinnerArray.length === count + 1) {
        axios
          .put(
            `${process.env.API_URL_PREFIX}/api/v1/user/cleanWinnerArray/` +
            id,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            const timer = setTimeout(() => { }, 3000);

            // To clear or cancel a timer, you call the clearTimeout(); method,
            // passing in the timer object that you created into clearTimeout().

            return () => clearTimeout(timer);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [
    isVisiblePrize1,
    isVisiblePrize2,
    isVisiblePrize3,
    isVisiblePrize3,
    isVisiblePrize4,
    isVisiblePrize5,
    isVisiblePrize6,
  ]);

  const getWinnerStatus = async (ticketNumber: any) => {
    let token = storage.getToken();
    let formData = {
      ticketNumber: ticketNumber,
    };
    await axios
      .post(
        `${process.env.API_URL_PREFIX}/api/v1/user/userWinLooseStatus/` +
        id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res.data.userDashboardCount.length === 0) {
          setIsLostopen(true);
          const timer = setTimeout(() => {
            setIsLostopen(false);
          }, 8000);

          // To clear or cancel a timer, you call the clearTimeout(); method,
          // passing in the timer object that you created into clearTimeout().

          return () => clearTimeout(timer);
        } else if (res.data.userDashboardCount.length > 0) {
          let lotteryStatus = res?.data?.userDashboardCount?.[0]?.lotteryStatus;
          if (lotteryStatus == "Winner") {
            setIsWinopen(true);
            setWinnerWithTicket(false);
          } else if (lotteryStatus == "Winner+Free Ticket") {
            setIsWinopen(true);
            setWinnerWithTicket(true);
          } else if (lotteryStatus == "Loose") {
            setIsLostopen(true);
            setWinnerWithTicket(false);
            const looseTimer = setTimeout(() => {
              setIsLostopen(false);
            }, 8000);
            return () => clearTimeout(looseTimer);
          } else if (lotteryStatus == "Free Ticket") {
            setIsPrizeOpen(true);
            const timer = setTimeout(() => {
              setIsPrizeOpen(false);
            }, 8000);
            return () => clearTimeout(timer);
          }
          else {
            setIsWinopen(true);
          }
          const timer = setTimeout(() => {
            setIsWinopen(false);
          }, 8000);

          // To clear or cancel a timer, you call the clearTimeout(); method,
          // passing in the timer object that you created into clearTimeout().

          return () => clearTimeout(timer);
        }
        //setWinnerArray(res.data.data.lotteryDrawTktNum);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getWinner = async () => {
    let token = storage.getToken();
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/lotteryWinnerList/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setWinningList(res?.data.data);
        setIsSpinDone(true);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const getWinData = async () => {
    let token = storage.getToken();
    // let formData
    // useLotteryTicketList({lotteryId:"649d4943c0a0d39915560799"})

    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/rpmAndbreakAverage/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(async (res) => {
        setAvgSpeed(res.data.avgSpeed);
        await setSpinDuration1Value(res.data.avgSpeed);

        setDecelaration(res.data.deceleration);
        //setWinnerArray(res.data.data.lotteryDrawTktNum);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const setSpinDuration1Value = async (avgSpeedValue) => {
    setSpinDuration1((avgSpeedValue * 60) / 100);
  };

  const getChildComp1 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 6}
        mustStartSpinning={startSpinning6}
        prizeNumber={prizeNumber1}
        data={data1}
        innerRadius={82}
        heightPercentage={74}
        widthPercentage={74}
        width={400}
        height={400}
        stopSpinTime={15000}
        fontSize={fontSize1}
        textDistance={textDistance1}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin6(false);
          setIsVisiblePrize1(true);
          audio.play();
        }}
      />
    ),
    [startSpinning6]
  );
  const getChildComp2 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 5}
        mustStartSpinning={startSpinning5}
        prizeNumber={prizeNumber2}
        data={data2}
        innerRadius={79}
        heightPercentage={65}
        widthPercentage={65}
        fontSize={fontSize2}
        width={350}
        height={350}
        stopSpinTime={12000}
        textDistance={textDistance2}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin5(false);
          setIsVisiblePrize2(true);
          audio.play();
        }}
      />
    ),
    [startSpinning5]
  );
  const getChildComp3 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 4}
        mustStartSpinning={startSpinning4}
        prizeNumber={prizeNumber3}
        data={data3}
        innerRadius={76}
        heightPercentage={56}
        fontSize={fontSize3}
        widthPercentage={56}
        width={300}
        height={300}
        stopSpinTime={9000}
        textDistance={textDistance3}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin4(false);
          setIsVisiblePrize3(true);
          audio.play();
        }}
      />
    ),
    [startSpinning4]
  );
  const getChildComp4 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 3}
        mustStartSpinning={startSpinning3}
        prizeNumber={prizeNumber4}
        data={data4}
        innerRadius={73}
        fontSize={fontSize4}
        heightPercentage={48}
        widthPercentage={48}
        width={250}
        height={250}
        stopSpinTime={6000}
        textDistance={textDistance4}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin3(false);
          setIsVisiblePrize4(true);
          audio.play();
        }}
      />
    ),
    [startSpinning3]
  );
  const getChildComp5 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10 * 2}
        mustStartSpinning={startSpinning2}
        prizeNumber={prizeNumber5}
        data={data5}
        innerRadius={70}
        fontSize={fontSize5}
        heightPercentage={39}
        widthPercentage={39}
        width={200}
        height={200}
        stopSpinTime={3000}
        textDistance={textDistance5}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin2(false);
          setIsVisiblePrize5(true);
          audio.play();
        }}
      />
    ),
    [startSpinning2]
  );
  const getChildComp6 = useMemo(
    () => (
      <Wheel
        continueSpin={(avgSpeed / 60) * avgSpeed * 10}
        mustStartSpinning={startSpinning1}
        prizeNumber={prizeNumber6}
        data={data6}
        innerRadius={70}
        fontSize={15}
        heightPercentage={29}
        widthPercentage={29}
        width={180}
        height={180}
        stopSpinTime={0}
        textDistance={80}
        perpendicularText={true}
        startingOptionIndex={0}
        backgroundColors={backgroundColors}
        onStopSpinning={() => {
          // setMustSpin1(false);
          setIsVisiblePrize6(true);
          audio.play();
        }}
      />
    ),
    [startSpinning1]
  );

  const handleStartDraw = () => {
    setStartDraw(true);
    setLotteryPool(false);
  }

  const getTicketPurchaseSummary = async () => {
    let token = storage.getToken();
    await axios
      .get(
        `${process.env.API_URL_PREFIX}/api/v1/user/lotteryTicketPurchaseSummary/` +
        id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setNoOfTicketsSold(res?.data?.numOfTicketSold);
        setPercentageOfTicketSold(res?.data?.percentageTktSale);
        setTotalPrizeOfTicket(res?.data?.sumTicketPrice !== null ? res?.data?.sumTicketPrice : 0)
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <div style={{ paddingTop: "85px" }}></div>
      <Box
        className="wheel-container"
        id="container"
        sx={{
          backgroundColor: isWinOpen ? "transparent" : "rgba(5, 5, 24, 0.8)",
          borderRadius: "10px",
          p: { md: "15px", xs: "5px" },
          px: { md: "23px", xs: "0px" },
          m: { md: "15px", xs: "2px" },
          minHeight: { xs: "290px", sm: "290px", md: "500px" },
          height: "auto",
          pb: "40px !important",
        }}
      >
        <Box sx={{ borderRadius: "10px", py: "62px", p: "0" }}>
          <Grid container>
            <Grid item xs={3} md={3} sx={{ maxHeight: "500px", mt: "20px" }}>
              <Box className="lotterynumber-div">
                <Box
                  id="outer"
                  className={classes.boxShadow}
                  sx={{
                    width: { md: "312px", xs: "145px" },
                    height: { md: "47px", xs: "20px" },
                    borderRadius: "12px",
                    display: "flex",
                    mb: "20px",
                    border: "4px solid #3b97bb",
                  }}
                >
                  <Box
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "52px", xs: "20px" },
                      height: { md: "47px", xs: "20px" },
                      background: isVisiblePrize1 ? "#750787" : "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {isVisiblePrize1 ? (
                      <CountUp start={0} end={prizeNumber1} delay={0}>
                        {({ countUpRef }) => (
                          <Box
                            sx={{
                              color: "#FFFF",
                              fontSize: { xs: "10px", md: "40px" },
                              fontWeight: "bold",
                            }}
                          >
                            <span ref={countUpRef} />
                          </Box>
                        )}
                      </CountUp>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "52px", xs: "20px" },
                      height: { md: "47px", xs: "20px" },
                      background: isVisiblePrize2 ? "#1C6CF2" : "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {isVisiblePrize2 ? (
                      <CountUp start={0} end={prizeNumber2} delay={0}>
                        {({ countUpRef }) => (
                          <Box
                            sx={{
                              color: "#FFFF",
                              fontSize: { xs: "10px", md: "40px" },
                              fontWeight: "bold",
                            }}
                          >
                            <span ref={countUpRef} />
                          </Box>
                        )}
                      </CountUp>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "52px", xs: "20px" },
                      height: { md: "47px", xs: "20px" },
                      background: isVisiblePrize3 ? "#008026" : "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {isVisiblePrize3 ? (
                      <CountUp start={0} end={prizeNumber3} delay={0}>
                        {({ countUpRef }) => (
                          <Box
                            sx={{
                              color: "#FFFF",
                              fontSize: { xs: "10px", md: "40px" },
                              fontWeight: "bold",
                            }}
                          >
                            <span ref={countUpRef} />
                          </Box>
                        )}
                      </CountUp>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "52px", xs: "20px" },
                      height: { md: "47px", xs: "20px" },
                      background: isVisiblePrize4 ? "#E40303" : "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {isVisiblePrize4 ? (
                      <CountUp start={0} end={prizeNumber4} delay={0}>
                        {({ countUpRef }) => (
                          <Box
                            sx={{
                              color: "#FFFF",
                              fontSize: { xs: "10px", md: "40px" },
                              fontWeight: "bold",
                            }}
                          >
                            <span ref={countUpRef} />
                          </Box>
                        )}
                      </CountUp>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Box
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "52px", xs: "20px" },
                      height: { md: "47px", xs: "20px" },
                      background: isVisiblePrize5 ? "#FF8C00" : "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {isVisiblePrize5 ? (
                      <CountUp start={0} end={prizeNumber5} delay={0}>
                        {({ countUpRef }) => (
                          <Box
                            sx={{
                              color: "#FFFF",
                              fontSize: { xs: "10px", md: "40px" },
                              fontWeight: "bold",
                            }}
                          >
                            <span ref={countUpRef} />
                          </Box>
                        )}
                      </CountUp>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box
                    className={classes.boxShadow}
                    sx={{
                      width: { md: "52px", xs: "20px" },
                      height: { md: "47px", xs: "20px" },
                      background: isVisiblePrize6 ? "#e55934" : "transparent",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {isVisiblePrize6 ? (
                      <CountUp start={0} end={prizeNumber6} delay={0}>
                        {({ countUpRef }) => (
                          <Box
                            sx={{
                              color: "#FFFF",
                              fontSize: { xs: "10px", md: "40px" },
                              fontWeight: "bold",
                            }}
                          >
                            <span ref={countUpRef} />
                          </Box>
                        )}
                      </CountUp>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <div className="mob-screen-audio">
                  <audio ref={audioElement} src={sound} />
                  <IconButton onClick={toggleMute} sx={{ padding: { xs: "0", md: "10px" } }}>
                    {isMuted ? (
                      <VolumeOffIcon sx={{ color: "white" }} />
                    ) : (
                      <VolumeUpIcon sx={{ color: "white" }} />
                    )}
                  </IconButton>
                </div>
                <Box
                  id="lottery-tickets-list-wheellpage"
                  className={classes.list}
                  sx={{ mt: "20px", maxHeight: "320px" }}
                >
                  <Grid container>
                    {dataLottery?.data?.data?.map((row) => (
                      <Grid item md={5}>
                        <Paper
                          elevation={3}
                          className={classes.ticketContainer}
                          sx={{ marginTop: "20px" }}
                        >
                          <div className={classes.detailContainer}>
                            <div>
                              <Typography
                                variant="body2"
                                className={classes.detailLabel}
                              >
                                Ticket no.
                              </Typography>
                              <Typography
                                variant="h6"
                                className={classes.detailNumber}
                              >
                                {row}
                              </Typography>
                            </div>
                            <Divider
                              sx={{
                                borderRight: "0.46px black dotted",
                                position: "absolute",
                                /* float: right; */ left: "85%",
                                top: "2%",
                              }}
                              orientation="vertical"
                            />
                          </div>
                          <img
                            src={arrow}
                            alt="Up Arrow"
                            className={`${classes.arrowImage} ${classes.polyup}`}
                          />

                          <img
                            src={arrow}
                            alt="Down Arrow"
                            className={`${classes.arrowImage} ${classes.polydown}`}
                          />
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              md={6}
              sx={{
                display: "center",
                justifyContent: "center",
                alignItems: "center",
                mt: "20px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  position: "relative",
                  bottom: { xs: "83%", md: "80%" },
                  transform: { xs: "scale(0.57)", md: "scale(1)" },
                }}
                className={classes.leftPosition}
              >
                <div
                  className="App"
                  style={{ fontFamily: "Nunito", position: "absolute" }}
                >
                  <div className="box-spin box-spin1">
                    <div className="font-spin">
                      {showModal ? (
                        <span
                          style={{
                            position: "relative",
                            top: "0px",
                            paddingLeft: "40px",
                            paddingRight: "40px",
                          }}
                        >
                          <Countdown date={new Date(`${remainingTime}`)} />
                        </span>
                      ) : (
                        <>
                          <span style={{ position: "relative", top: "-5px" }}>
                            Spin {countValue}
                          </span>
                          <span
                            className="wheel-arrow"
                            style={{ position: "relative" }}
                          >
                            <ArrowRightAltIcon />
                          </span>
                          <span style={{ position: "relative", top: "-5px" }}>
                            <img
                              src={whitet}
                              height="14"
                              width="14"
                              style={{ marginRight: "4px" }}
                            />
                            {lotterySpin[count]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="speed">
                    <img src={speed} alt="speed" className="rpm2" />
                    <p style={{ color: "#fffff" }} className="speed3">
                      Break <br /> {decelaration.toFixed(2)}
                    </p>
                  </div>
                  <header className="App-header">
                    <div className="box-spin box-spin2">
                      <div className="font-spin">
                        {showModal ? (
                          <span
                            style={{
                              position: "relative",
                              top: "0px",
                              paddingLeft: "40px",
                              paddingRight: "40px",
                            }}
                          >
                            <Countdown date={new Date(`${remainingTime}`)} />
                          </span>
                        ) : (
                          <>
                            <span style={{ position: "relative", top: "-5px" }}>
                              Spin {countValue}
                            </span>
                            <span
                              className="wheel-arrow"
                              style={{ position: "relative" }}
                            >
                              <ArrowRightAltIcon />
                            </span>
                            <span style={{ position: "relative", top: "-5px" }}>
                              <img
                                src={whitet}
                                height="14"
                                width="14"
                                style={{ marginRight: "4px" }}
                              />
                              {lotterySpin[count]}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="parent parent-div">
                      {/* <div><p style={{textAlign:"center"}}>Spinning is winning</p></div> */}
                      <div className="circle0 circle-zero">
                        <div className="outerImg"></div>
                      </div>
                      <div className="circle1 circle-first">
                        {getChildComp1}
                      </div>
                      <div className="circle2 circle-second" id="wheel-2">
                        {getChildComp2}
                      </div>
                      <div className="circle3 circle-third">
                        {getChildComp3}
                      </div>
                      <div className="circle4 circle-four">{getChildComp4}</div>
                      <div className="circle5 circle-five">{getChildComp5}</div>
                      <div className="circle6 circle-six">
                        {getChildComp6}
                        {/* <button
                        className={"spin-button"}
                        onClick={handleSpinClick}
                      >
                        SPIN
                      </button> */}
                      </div>

                      <div className="circle7 circle-seven">
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        ></div>
                      </div>
                      <div className="circle8 circle-eight">
                        <div
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            display: "block",
                          }}
                        >
                          <span className="spiner-circle">
                            {avgSpeed.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </header>
                  <div className="rpm1">
                    <img src={rpm} alt="rpm" className="rpm2" />
                    <p style={{ color: "#fffff" }} className="rpm3">
                      RPM<br /> {avgSpeed.toFixed(2)}
                    </p>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid
              className="table-oriantation-fix"
              item
              xs={4}
              md={3}
              sx={{
                display: { xs: "block", md: "block" }, mt: {
                  sm: "0px !important",
                  md: "0px !important",
                  lg: "20px !important",
                }
              }}
            >
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  ml: { xs: "56px", md: "0px" },
                }}
              >
                <Table
                  className={classes.table}
                  aria-label="custom table"
                  sx={{
                    p: { xs: "0px", sm: "10px", md: "25px" },
                    minWidth: "auto !important",
                    display: "block",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#0D0D36 !important" }}>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          sx={{
                            color: "#FFFFFF",
                            p: {
                              sm: "0px !important",
                              md: "0px !important",
                              lg: "0px !important",
                              xs: "0 !important",
                            },
                          }}
                        >
                          {lotteryNameData}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "16px" } }}
                      ></TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        sx={{ p: { xs: "0px", md: "16px" } }}
                      >
                        <img
                          src={
                            lotteryImage !== ""
                              ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${lotteryImage}`
                              : lotteryName
                          }
                          alt="lotteryName"
                          height="60px"
                          width="60px"
                          style={{
                            border: "2px solid #3b97bb",
                            borderRadius: "9px",
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    className={classes.list}
                    sx={{
                      mt: "20px",
                      maxHeight: "320px",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <TableRow
                      key={0}
                      sx={{
                        backgroundColor: "#3b97bb",
                        opacity: "1",
                        width: "100%",
                        display: "block",
                      }}
                    >
                      <TableCell
                        colSpan={2}
                        className={classes.tableData}
                        sx={{
                          opacity: "1 !important",
                          p: { xs: "0px", md: "16px" },
                          borderBottom: "none !important",
                          lineHeight: "1px",
                          pt: "0px !important",
                          pb: "0px !important"

                        }}
                      >
                        <p style={{ width: "max-content" }}>

                          Total Prize money
                        </p>
                      </TableCell>
                      {/* <TableCell className={classes.tableData}></TableCell> */}
                      <TableCell
                        className={classes.tableData}
                        sx={{
                          textAlign: "center",
                          opacity: "1 !important",
                          width: "46%",
                          p: { xs: "0px", md: "16px" },
                          borderBottom: "none !important",
                          lineHeight: "1px",
                          pt: "0px !important",
                          pb: "0px !important"

                        }}
                      >
                        <img src={whitet} height="13" width="13" />{" "}
                        {/* {lotterySpin.length > 0
                          ? lotterySpin.reduce(
                              (acc, curr) => acc + parseInt(curr),
                              0
                            )
                          : ""} */}
                        <CurrencyFormat value={lotterySpin.length > 0
                          ? lotterySpin.reduce(
                            (acc, curr) => acc + parseInt(curr),
                            0
                          )
                          : 0} thousandSeparator={'.'} displayType={'text'} decimalSeparator={','} />
                      </TableCell>
                    </TableRow>
                    {lotterySpin.map((row, index) => (
                      <TableRow
                        key={index + 1}
                        sx={{ width: "100%", display: "block", borderBottom: "1px solid #0D0D39" }}
                      >
                        <TableCell
                          // colSpan={2}
                          className={classes.tableData}
                          sx={{
                            p: { xs: "0px", md: "4px" },
                            paddingRight: { md: "52px", xs: "15px" },
                            width: "50%",
                            borderBottom: "none !important",
                            lineHeight: "1px",
                          }}
                        >
                          <ul style={{ margin: "0px" }}>
                            <li
                              className={
                                index === count ? "active" : "inactive"
                              }
                            >
                              <p className="m-0 mw-max" >

                                {index + 1 + "th spin"}
                              </p>
                            </li>
                          </ul>
                        </TableCell>
                        {/* <TableCell className={classes.tableData}></TableCell> */}
                        <TableCell
                          className={classes.tableData}
                          sx={{
                            textAlign: "center",
                            p: { xs: "0px", md: "4px" },
                            width: "50%",
                            borderBottom: "none !important",
                            lineHeight: "1px",

                          }}
                        >
                          <span
                            className={index === count ? "active" : "inactive"}
                          >
                            {index === count ? (
                              <img
                                src={yellowt}
                                height="12"
                                width="12"
                                style={{ marginRight: "4px" }}
                              />
                            ) : (
                              <img
                                src={whitet}
                                height="12"
                                width="12"
                                style={{ marginRight: "4px" }}
                              />
                            )}
                            {/* {lotterySpin[index]} */}
                            <CurrencyFormat value={lotterySpin[index]} thousandSeparator={'.'} displayType={'text'} decimalSeparator={','} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {WinnerArray.length === countValue && hasCompletedRotation ? (
        <Grid
          container
          display={"flex"}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          spacing={2}
          sx={{ marginBottom: "20px" }}
        >
          <Grid
            item
            xs={10}
            md={6}
            sx={{
              paddingLeft: "34px",
              marginLeft: "20px",
              marginBottom: "10px",
            }}
          >
            <Typography component="h4" variant="h4" sx={{ mb: "20px" }}>
              Winning Tickets
            </Typography>
            {WinnerArray.length === countValue ? (
              <WinningHistoryWheel />
            ) : (
              <Typography component="h4" variant="h4" sx={{ mb: "20px" }}>
                No winner
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            md={5}
            xl={5}
            sx={{
              display: { md: "flex", xs: "none" },
              justifyContent: "flexStart",
              alignItems: "end",
              mt: "54px",
            }}
          >
            <img src={ad} alt="image" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      {/* //backend --> lottery 10 ---> user(2) win */}
      {/* {winnerStatus? <WinModal open={isWinOpen} onClose={handleWinClose} />:
      <LostModal open={isLostOpen} onClose={handleLostClose} />} */}
      <WinModal
        open={isWinOpen}
        onClose={handleWinClose}
        winnerPrize={lotterySpin[count]}
        ticketNumber={WinnerArray[count]}
        countIndex={count}
        winnerWithTicket={winnerWithTicket}
      />
      <LostModal
        open={isLostOpen}
        onClose={handleLostClose}
        message={message}
      />
      <PrizeModal
        open={isPrizeOpen}
        onClose={handlePrizeClose}
      // message={message}
      />

      {lotteryPool ? (
        <LotteryPoolModal
          open={lotteryPool}
          onClose={handleLotteryPoolClose}
          noOfTicketsSold={noOfTicketsSold}
          percentageOfticketsSold={percentageOfTicketSold}
          totalPrizeofTickets={totalPrizeOfTicket}
          handleStartDraw={handleStartDraw}
        />
      ) : null}

      {isWinOpen ? (
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            preset: "fireworks",
            particles: {
              color: {
                value: ["#5bc0eb", "#fde74c", "#9bc53d", "#e55934", "#fa7921"],
              },
              background: {
                color: {
                  value: "transparent",
                },
                background: {
                  color: {
                    value: "#000000",
                  },
                },
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: false,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    bubble: {
                      distance: 400,
                      duration: 2,
                      opacity: 0.8,
                      size: 40,
                    },
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#ffffff",
                  },
                  links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: false,
                    opacity: 0.4,
                    width: 1,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outMode: "bounce",
                    random: true,
                    speed: 2,
                    straight: false,
                  },
                  fullScreen: {
                    enable: true,
                    zIndex: 0,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 500,
                    },
                    value: 80,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: true,
                    value: 1,
                  },
                },
                detectRetina: true,
                fullScreen: {
                  enable: true,
                  zIndex: -1,
                },
              },
            },
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};
