import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import blockchain from "@/assets/blockchain.png";
import startImg from "@/assets/startImg.png";
import choose from "@/assets/choose.svg";
import select from '@/assets/select.svg';
import claim from '@/assets/claim.svg';
import buy from '@/assets/buy.svg';
import watch from '@/assets/watch.svg';
import ArrowDropDownCircleIcon from '../../../src/assets/down-arrow.png';

const MyButtonBox = () => {
    console.log("fff")
  const [imageIndex, setImageIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeButton, setActiveButton] = useState(0);

  var cardStyle3 = {
    display: "block",
    transitionDuration: "0.3s",
    background: `url(${choose})`,
    backgroundRepeat: "no-repeat",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
    backgroundSize: "cover",
  };

  const handleButtonClick = (index) => {
    setActiveIndex(index);
    setActiveButton(index);
  };

  return (
<Grid xs={12} >
    <Box  style={cardStyle3} sx={{ pt:"30px"}}><Box sx={{
          boxSizing: "border-box",
          color: "#FFFFF",
          border: "0.849576px solid rgba(255, 255, 255, 0.2)",
          borderRadius: "42.4788px",
         
      }}
      className='Choose-homescreen'>
          <Button   className={`button ${activeButton === 0 ? 'active' : ''}`} onClick={() => handleButtonClick(0)}>Choose {activeButton === 0 ? <img src={ArrowDropDownCircleIcon}></img> : ''}</Button>
          <Button   className={`button ${activeButton === 1 ? 'active' : ''}`} onClick={() => handleButtonClick(1)}>Buy  {activeButton === 1 ? <img src={ArrowDropDownCircleIcon}></img> : ''}</Button>
          <Button   className={`button ${activeButton === 2 ? 'active' : ''}`} onClick={() => handleButtonClick(2)}>See {activeButton === 2 ? <img src={ArrowDropDownCircleIcon}></img> : ''}</Button>
          <Button   className={`button ${activeButton === 3 ? 'active' : ''}`} onClick={() => handleButtonClick(3)}>Win {activeButton === 3 ? <img src={ArrowDropDownCircleIcon}></img> : ''}</Button>
      </Box><Box sx={{ display: 'flex', justifyContent: 'center', py:"40px"}}>

              {activeIndex === 0 && (
                  <React.Fragment>
                      <img src={select} alt="Image 1" />
                      <Typography sx={{maxWidth: "160px",
  minWidth: "100px", paddingLeft:"20px"}}>Select the lottery which you want to play</Typography>
                  </React.Fragment>
              )}


              {activeIndex === 1 && (
                  <React.Fragment>
                      <img src={buy} alt="Image 2" />
                      <Typography sx={{maxWidth: "160px",
  minWidth: "100px", paddingLeft:"20px"}}>Buy your tickets.</Typography>
                  </React.Fragment>
              )}


              {activeIndex === 2 && (
                  <React.Fragment>
                      <img src={watch} alt="Image 3" />
                      <Typography sx={{maxWidth: "160px",
  minWidth: "100px", paddingLeft:"20px"}}>Watch the lottery draw.</Typography>
                  </React.Fragment>
              )}


              {activeIndex === 3 && (
                  <React.Fragment>
                      <img src={claim} alt="Image 4" />
                      <Typography sx={{maxWidth: "160px",
  minWidth: "100px", paddingLeft:"20px"}}>Claim your prize.</Typography>
                  </React.Fragment>
              )}

          </Box></Box>
          </Grid>
  );
};
export default MyButtonBox;