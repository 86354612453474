import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopContainer from "./topContainer";
import UpcomingLottery from "./upcomingLottery";
import QuickDraws from "./quickDraws";
import WinningDetails from "./winningDetails";
import { theme } from "@/components/Elements/theme";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { getHomeData, useHomeScreenList } from "../api/getHomeData";
import MyButtonBox from "@/components/Elements/crouselButton";
import RecentActivitiesHome from "./RecentActivitiesHOme";

export const HomeScreen = () => {
  const hidden = useMediaQuery("(min-width: 567px)");
  const hiddenDesktop = useMediaQuery("(max-width: 568px)");
  const [status, setStatus] = useState(false)
  const getData = async () => {
    const res = await axios.get(`${process.env.API_URL_PREFIX}/api/v1/user/getsys`);
    setStatus(res?.data?.data[0]?.status || false)
  };
  const lotteryData = useHomeScreenList({})
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);
  
  return (
    <>
      {hidden ? (
        <Box sx={{ color: "#FFFFFF", pl: {xs:"30px", md:"70px"}, pr:"70px", minHeight: "100vh" }}>
          <TopContainer status={status} dataRecent={lotteryData.data} />
          <UpcomingLottery data={lotteryData.data}/>
          {/* <QuickDraws  data={lotteryData.data}/> */}
          <Grid container>
            <Grid item xs={6} ><MyButtonBox /></Grid>
            <Grid item xs={6} ><RecentActivitiesHome dataRecent={lotteryData.data} /></Grid>
          </Grid>
          <WinningDetails  data={lotteryData.data} />
        </Box>
      ) : (
        <Box sx={{ color: "#FFFFFF", pl: "0px", minHeight: "100vh" }}>
           <TopContainer status={status} dataRecent={lotteryData.data} />
           <UpcomingLottery data={lotteryData.data}/>
           <WinningDetails  data={lotteryData.data} />
           <Grid container>
            <Grid item xs={6} ><MyButtonBox /></Grid>
            <Grid item xs={6} ><RecentActivitiesHome dataRecent={lotteryData.data} /></Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HomeScreen;
