import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid } from "@mui/material";
import promotionFirst from "@/assets/promotionFirst.png";
import { theme } from "@/components/Elements/theme";
import { url } from "inspector";
import HomeScreenImg from "@/assets/earth.jpg";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
import { useLicenseList } from "../api/getLicense";
import { useRulesList } from "../api/getRules";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
}));

export default function Licensing() {
  const classes = useStyles();
  const DataValue = useRulesList({});
  const auth = useAuth();

  return (
    <>
      {auth.user ? <Header /> : <HeaderValue />}
      <Box
        sx={{
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          padding: 0,
          pt: "60px",
        }}
      >
        <Box
          sx={{ backgroundColor: "rgba(5,5,24,0.8)", borderRadius: "10px" }}
        />
        <Box sx={{ p: { xs: "34px", md: "50px" } }}>
          <Grid
            container
            display={"flex"}
            direction={"column"}
            sx={{
              backgroundColor: "rgba(5,5,24,0.8)",
              borderRadius: "10px",
            }}
          >
            <Grid item xs={12} sm={10} md={8} lg={6} style={{    margin: "0px 0px 0px -277px"}}>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ marginBottom: "40px", p: theme.spacing(1) }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h1 style={{ textAlign: "center", color: "#3C37FF" }}>
                    {DataValue?.data && DataValue?.data?.[0]?.heading}
                  </h1>
                  <Card
                    sx={{
                      margin: "0 auto",
                      background: "none",
                      display: "flex",
                    }}
                  >
                    <Grid item xs={5} sm={10} md={8} lg={6}>
                      <img
                        src={
                          `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/` +
                          DataValue?.data?.[0]?.image
                        }
                        alt="donation image"
                        style={{
                          borderRadius: "12px",
                          width: "206px",
                          height: "250px",
                          margin: "114px 0px 0px 353px"
                        }}
                      />
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: "920px",
                        height: "auto",
                        margin: "50px",
                        overflow: "auto",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "700px",
                          color: "white",
                          textAlign: "start",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          <p>
                            {DataValue?.data?.[0]?.description !==
                              undefined && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    DataValue?.data &&
                                    DataValue?.data?.[0]?.description,
                                }}
                              />
                            )}
                            {}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
